var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { FormControlLabel } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { Controller } from "react-hook-form";
var GenericControllerCheckboxInput = function (_a) {
    var name = _a.name, control = _a.control, label = _a.label, error = _a.error, isDisabled = _a.isDisabled, rules = _a.rules, defaultChecked = _a.defaultChecked, rest = __rest(_a, ["name", "control", "label", "error", "isDisabled", "rules", "defaultChecked"]);
    return (React.createElement(FormControlLabel, { label: label, control: React.createElement(Controller, { name: name, control: control, defaultValue: !!defaultChecked, rules: rules, render: function (_a) {
                var _b = _a.field, onChange = _b.onChange, onBlur = _b.onBlur, value = _b.value, error = _a.fieldState.error;
                return (React.createElement(Checkbox, { onChange: onChange, defaultChecked: !!defaultChecked, value: value, disabled: isDisabled }));
            } }) }));
};
export default GenericControllerCheckboxInput;
