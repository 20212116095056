import React, { useState } from "react";
import { Typography, Paper, Box, Button, MenuItem } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/";
import { useForm } from "react-hook-form";
import Layout from "../Layout";
import GenericControllerTextInput from "../../components/GenericControllerTextInput";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { hasPermission } from "../../utils/utils";
import GenericControllerDropdownInput from "../../components/GenericControllerDropdownInput";
import { Link } from "@inertiajs/react";
export default function UrlBuilderForm(props) {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    var auth = props["auth"];
    var response_data = props["response_data"];
    var objectives = response_data["utm_builder_objectives"];
    var sources = response_data["utm_builder_sources"];
    var campaigns = response_data["utm_builder_campaigns"];
    var mediums = response_data["utm_builder_mediums"];
    var _j = useForm(), register = _j.register, control = _j.control, errors = _j.formState.errors, watch = _j.watch;
    var _k = useState(""), objective = _k[0], setObjective = _k[1];
    var _l = useState(""), source = _l[0], setSource = _l[1];
    var _m = useState(""), campaign = _m[0], setCampaign = _m[1];
    var _o = useState(""), medium = _o[0], setMedium = _o[1];
    var watchUrl = watch("url", "");
    var watchObjective = watch("objective_id", objective);
    var watchSource = watch("source_id", source);
    var watchCampaign = watch("campaign_id", campaign);
    var watchMedium = watch("medium_id", medium);
    var watchContent = watch("content", "");
    var codify = function (str) {
        str = str.toLowerCase();
        str = str.replace(/[^a-z0-9]+/g, "_");
        str = str.replace(/^-+|-+$/g, "");
        return str;
    };
    var objectiveCode = (_b = (_a = objectives.find(function (el) { return el.id == watchObjective; })) === null || _a === void 0 ? void 0 : _a.code) !== null && _b !== void 0 ? _b : "";
    var sourceCode = (_d = (_c = sources.find(function (el) { return el.id == watchSource; })) === null || _c === void 0 ? void 0 : _c.code) !== null && _d !== void 0 ? _d : "";
    var campaignCode = (_f = (_e = campaigns.find(function (el) { return el.id == watchCampaign; })) === null || _e === void 0 ? void 0 : _e.code) !== null && _f !== void 0 ? _f : "";
    var mediumCode = (_h = (_g = mediums.find(function (el) { return el.id == watchMedium; })) === null || _g === void 0 ? void 0 : _g.code) !== null && _h !== void 0 ? _h : "";
    var result = "";
    if (watchUrl) {
        var has_question_mark = watchUrl.indexOf("?");
        if (has_question_mark > -1) {
            result += watchUrl + "&";
        }
        else {
            result += watchUrl + "?";
        }
    }
    if (objectiveCode) {
        result += "utm_objective=".concat(objectiveCode);
    }
    if (sourceCode) {
        result += "&utm_source=".concat(sourceCode);
    }
    if (campaignCode) {
        result += "&utm_campaign=".concat(campaignCode);
    }
    if (mediumCode) {
        result += "&utm_medium=".concat(mediumCode);
    }
    var strContent = codify(watchContent);
    if (watchContent) {
        result += "&utm_content=".concat(strContent);
    }
    var message = "";
    if (!watchUrl) {
        message = "Please enter a url (required):";
    }
    else if (!watchObjective) {
        message = "Please choose an objective (required):";
    }
    else if (!watchSource) {
        if (watchContent) {
            message = "Please choose a source (optional).";
        }
        else {
            message =
                "Please choose a source (optional) and/or enter content (optional).";
        }
    }
    else if (!watchCampaign) {
        if (watchContent) {
            message = "Please choose a campaign (optional).";
        }
        else {
            message =
                "Please choose a campaign (optional) and/or enter content (optional).";
        }
    }
    else if (!watchMedium) {
        if (watchContent) {
            message = "Please choose a medium (optional).";
        }
        else {
            message =
                "Please choose a medium (optional) and/or enter content (optional)";
        }
    }
    else {
        if (watchContent) {
            message = "";
        }
        else {
            message = "Please enter content (optional):";
        }
    }
    return (React.createElement(Layout, { title: "Utm URL Builder", menuOpen: true, toggleMenuOpen: function () { } },
        React.createElement(Grid, { container: true, xs: 12, spacing: 2 },
            React.createElement(Grid, { xs: 12, container: true, spacing: 2 },
                React.createElement(Grid, { xs: 10 },
                    React.createElement(Typography, { variant: "h5", component: "h3", className: "section-title" }, "UTM URL Builder")),
                React.createElement(Grid, { xs: 2, container: true, justifyContent: "flex-end" }, hasPermission(auth, "edit utm_builder") && (React.createElement(Link, { href: "utm-builder/admin/view/objective" }, "UTM Builder Manager")))),
            message && (React.createElement(Grid, { xs: 12, container: true, spacing: 2 },
                React.createElement(Grid, { xs: 12, md: 6 },
                    React.createElement(Typography, { variant: "body1" }, message),
                    watchObjective != "" && (React.createElement(Typography, { variant: "body1" }, "Click 'COPY URL' to copy the url to your clipboard."))))),
            React.createElement(Grid, { xs: 12, container: true, spacing: 2 },
                React.createElement(Grid, { xs: 12, md: 6 },
                    React.createElement(GenericControllerTextInput, { control: control, defaultValue: "", name: "url", label: "Url" }))),
            watchUrl != "" && (React.createElement(Grid, { xs: 12, container: true, spacing: 2 },
                React.createElement(Grid, { xs: 12, md: 6 },
                    React.createElement(GenericControllerDropdownInput, { label: "Objective", name: "objective_id", id: "".concat("objective", "_select"), defaultValue: watchObjective, handleChange: function (e) {
                            return setObjective(e.target.value);
                        }, control: control, rules: {
                            required: true,
                        }, error: "objective_id" in errors, options: !!(objectives === null || objectives === void 0 ? void 0 : objectives.length) &&
                            objectives.map(function (option) { return (React.createElement(MenuItem, { key: option.id, value: option.id }, option.name)); }) })))),
            watchObjective != "" && (React.createElement(Grid, { xs: 12, container: true, spacing: 2 },
                React.createElement(Grid, { xs: 12, md: 6 },
                    React.createElement(GenericControllerDropdownInput, { label: "Source", name: "source_id", control: control, id: "".concat("source", "_select"), defaultValue: watchSource, handleChange: function (e) {
                            setSource(e.target.value);
                            setCampaign("");
                            setMedium("");
                        }, rules: {
                            required: true,
                        }, error: "source_id" in errors, options: !!(sources === null || sources === void 0 ? void 0 : sources.length) &&
                            sources.map(function (option) { return (React.createElement(MenuItem, { key: option.id, value: option.id }, option.name)); }) })))),
            watchSource != "" && (React.createElement(Grid, { xs: 12, container: true, spacing: 2 },
                React.createElement(Grid, { xs: 12, md: 6 },
                    React.createElement(GenericControllerDropdownInput, { label: "Campaign", name: "campaign_id", control: control, id: "".concat("campaign", "_select"), defaultValue: watchCampaign, handleChange: function (e) {
                            setCampaign(e.target.value);
                            setMedium("");
                        }, rules: {
                            required: true,
                        }, error: "campaign_id" in errors, options: !!(campaigns === null || campaigns === void 0 ? void 0 : campaigns.length) &&
                            campaigns
                                .filter(function (el) {
                                return el.utm_builder_source_id ==
                                    watchSource;
                            })
                                .map(function (option) { return (React.createElement(MenuItem, { key: option.id, value: option.id }, option.name)); }) })))),
            watchCampaign != "" && (React.createElement(Grid, { xs: 12, container: true, spacing: 2 },
                React.createElement(Grid, { xs: 12, md: 6 },
                    React.createElement(GenericControllerDropdownInput, { label: "Medium", name: "medium_id", control: control, id: "".concat("medium", "_select"), defaultValue: watchMedium, handleChange: function (e) {
                            return setMedium(e.target.value);
                        }, rules: {
                            required: true,
                        }, error: "medium_id" in errors, options: !!(mediums === null || mediums === void 0 ? void 0 : mediums.length) &&
                            mediums
                                .filter(function (el) {
                                return el.utm_builder_campaign_id ==
                                    watchCampaign;
                            })
                                .map(function (option) { return (React.createElement(MenuItem, { key: option.id, value: option.id }, option.name)); }) })))),
            watchObjective != "" && (React.createElement(Grid, { xs: 12, container: true, spacing: 2 },
                React.createElement(Grid, { xs: 12, md: 6 },
                    React.createElement(GenericControllerTextInput, { control: control, defaultValue: "", name: "content", label: "Content" })))),
            watchObjective && (React.createElement(React.Fragment, null,
                React.createElement(Grid, { xs: 12, container: true, spacing: 2 },
                    React.createElement(Box, { component: Paper, m: 1, p: 2 },
                        React.createElement(Typography, null, result))),
                React.createElement(Grid, { xs: 12, container: true, spacing: 2 },
                    React.createElement(Grid, { xs: 12, md: 6 },
                        React.createElement(Button, { color: "primary", onClick: function () {
                                navigator.clipboard.writeText(result);
                            } },
                            React.createElement(FileCopyIcon, null),
                            "Copy URL"))))))));
}
