import { Link, usePage } from "@inertiajs/react";
import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import NumberFormat from "react-number-format";
import { helperFunctions } from "../../../Helpers";
import { hasPermission } from "../../../utils/utils";
export var BrokerSearchCard = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
    var broker = _a.broker;
    var auth = usePage().props.auth;
    return (React.createElement(Grid, { container: true, style: {
            borderLeft: "10px #035399 solid",
            borderRight: "2px #035399 solid",
            borderBottom: "2px #035399 solid",
            borderTop: "2px #035399 solid",
            padding: "5px",
            borderRadius: "3px",
        } },
        React.createElement(Grid, { container: true, item: true, xs: 3 },
            React.createElement(Grid, { item: true, xs: 12 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2", component: "span" },
                        "Name:",
                        " "),
                    React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" }, component: "span" }, (_c = (_b = broker === null || broker === void 0 ? void 0 : broker.user) === null || _b === void 0 ? void 0 : _b.name) !== null && _c !== void 0 ? _c : "")),
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2", component: "span" },
                        "Broker ID:",
                        " "),
                    React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" }, component: "span" }, (_d = broker === null || broker === void 0 ? void 0 : broker.id) !== null && _d !== void 0 ? _d : "")),
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2", component: "span" },
                        "Email:",
                        " "),
                    React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" }, component: "span" }, (_f = (_e = broker === null || broker === void 0 ? void 0 : broker.user) === null || _e === void 0 ? void 0 : _e.email) !== null && _f !== void 0 ? _f : "")),
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2", component: "span" },
                        "Phone:",
                        " "),
                    React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" }, component: "span" },
                        React.createElement(NumberFormat, { value: broker === null || broker === void 0 ? void 0 : broker.phone, displayType: "text", format: "(###) ###-####" }),
                        " ")))),
        React.createElement(Grid, { container: true, item: true, xs: 7 },
            React.createElement(Grid, { item: true, xs: 12 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2", component: "span" },
                        "Status:",
                        " "),
                    React.createElement(Typography, { variant: "body2", component: "span", style: {
                            fontWeight: "bold",
                            color: broker.status === "active"
                                ? "#3EBF3E"
                                : broker.status === "pending"
                                    ? "#1871BF"
                                    : broker.status === "inactive"
                                        ? "#F1221C"
                                        : "#222",
                        } }, broker.status === "deleted"
                        ? "inactive"
                        : (broker === null || broker === void 0 ? void 0 : broker.status) != undefined
                            ? broker.status
                            : "")),
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2", component: "span" },
                        "Business Name:",
                        " "),
                    React.createElement(Typography, { variant: "body2", component: "span", style: { fontWeight: "bold" } }, (_g = broker === null || broker === void 0 ? void 0 : broker.business_name) !== null && _g !== void 0 ? _g : "")),
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2", component: "span" },
                        "State:",
                        " "),
                    React.createElement(Typography, { component: "span", variant: "body2", style: { fontWeight: "bold" } }, (_j = (_h = broker === null || broker === void 0 ? void 0 : broker.state) === null || _h === void 0 ? void 0 : _h.name) !== null && _j !== void 0 ? _j : "")),
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2", component: "span" },
                        "Create At:",
                        " "),
                    React.createElement(Typography, { variant: "body2", component: "span", style: { fontWeight: "bold" } }, helperFunctions.getDateTime(broker === null || broker === void 0 ? void 0 : broker.created_at))))),
        React.createElement(Grid, { container: true, item: true, xs: 2, spacing: 1, justifyContent: "center", alignContent: "center" },
            React.createElement(Grid, { item: true, xs: 12 }, hasPermission(auth, "view broker") && (React.createElement(Link, { href: "/abs-broker/show/".concat((_k = broker === null || broker === void 0 ? void 0 : broker.user) === null || _k === void 0 ? void 0 : _k.id) },
                React.createElement(Button, { type: "button", color: "primary", variant: "outlined", size: "small", fullWidth: true }, "View")))),
            React.createElement(Grid, { item: true, xs: 12 }, hasPermission(auth, "edit broker") && (React.createElement(Link, { href: "/abs-broker/form/".concat(broker === null || broker === void 0 ? void 0 : broker.id) },
                React.createElement(Button, { type: "button", color: "secondary", variant: "outlined", size: "small", fullWidth: true }, "Edit")))),
            React.createElement(Grid, { item: true, xs: 12 }, hasPermission(auth, "edit permissions") && (React.createElement(Link, { href: "/abs-broker/permission/".concat((_l = broker === null || broker === void 0 ? void 0 : broker.user) === null || _l === void 0 ? void 0 : _l.id) },
                React.createElement(Button, { type: "button", variant: "outlined", size: "small", fullWidth: true }, "Permissions")))))));
};
