import React from "react";
import { Grid } from "@mui/material";
import { BrokerInfoCard } from "./BrokerInfoCard";
import { BrokerContactInfoCard } from "./BrokerContactInfoCard";
import { BrokerAddressCard } from "./BrokerAddressCard";
import { BrokerNoteCard } from "./BrokerNoteCard";
var BrokerDetail = function (_a) {
    var broker = _a.broker;
    return (React.createElement(Grid, { container: true, spacing: 2 },
        React.createElement(Grid, { item: true, xs: 12, md: 4 },
            React.createElement(BrokerInfoCard, { broker: broker })),
        React.createElement(Grid, { item: true, xs: 12, md: 4 },
            React.createElement(BrokerContactInfoCard, { broker: broker })),
        React.createElement(Grid, { item: true, xs: 12, md: 4 },
            React.createElement(BrokerAddressCard, { broker: broker })),
        React.createElement(Grid, { item: true, xs: 12, md: 4 },
            React.createElement(BrokerNoteCard, { broker: broker }))));
};
export default BrokerDetail;
