import React, { useState } from "react";
import { Button, Box, Modal, Dialog, DialogActions, DialogContent, DialogTitle, TextField, MenuItem, } from "@mui/material";
import { router } from "@inertiajs/react";
import { Controller, useForm } from "react-hook-form";
import GenericControllerDropdownInput from "../../../components/GenericControllerDropdownInput";
function addBusinessDays(originalDate, numDaysToAdd) {
    var Sunday = 0;
    var Saturday = 6;
    var daysRemaining = numDaysToAdd;
    var newDate = new Date(originalDate);
    while (daysRemaining > 0) {
        newDate.setDate(newDate.getDate() + 1);
        if (newDate.getDay() !== Sunday && newDate.getDay() !== Saturday) {
            daysRemaining--;
        }
    }
    return newDate;
}
var LeadSetDisposition = function (_a) {
    var _b, _c;
    var data = _a.data, options = _a.options, url = _a.url, title = _a.title, label = _a.label, name = _a.name, id = _a.id, buttonName = _a.buttonName, _d = _a.backgroundColor, backgroundColor = _d === void 0 ? "#392869" : _d;
    var _e = useForm(), control = _e.control, handleSubmit = _e.handleSubmit;
    var _f = useState(false), open = _f[0], setOpen = _f[1];
    var followUpDate = (_b = data === null || data === void 0 ? void 0 : data.follow_up_date) !== null && _b !== void 0 ? _b : "";
    var handleChange = function () {
        setOpen(true);
    };
    var handleClose = function () {
        setOpen(false);
    };
    var submitUpdate = function (data) {
        setOpen(false);
        var date = new Date();
        switch (data.disposition_id.toString()) {
            case "2":
            case "6":
                data.follow_up_date = addBusinessDays(date, 2)
                    .toISOString()
                    .slice(0, 10);
                break;
            case "5":
            case "9":
            case "24":
                data.follow_up_date = addBusinessDays(date, 1)
                    .toISOString()
                    .slice(0, 10);
                break;
            case "8":
            case "13":
                data.follow_up_date = addBusinessDays(date, 7)
                    .toISOString()
                    .slice(0, 10);
                break;
            case "12":
                data.follow_up_date = date.toISOString().slice(0, 10);
                break;
            case "14":
                data.follow_up_date = addBusinessDays(date, 5)
                    .toISOString()
                    .slice(0, 10);
                break;
            default:
                break;
        }
        router.post(url, data);
    };
    return (React.createElement(Box, null,
        React.createElement(Button, { variant: "contained", style: {
                backgroundColor: backgroundColor,
                color: "#FFF",
            }, onClick: handleChange, fullWidth: true, size: "small" }, buttonName),
        React.createElement(Modal, { open: open, onClose: handleClose },
            React.createElement(Dialog, { open: open, onClose: handleClose },
                React.createElement(DialogTitle, { id: "form-dialog-title" }, title),
                React.createElement(DialogContent, null,
                    React.createElement(GenericControllerDropdownInput, { name: name, rules: {
                            required: true,
                        }, control: control, id: "".concat(name, "_id"), label: label, defaultValue: (_c = data[name]) !== null && _c !== void 0 ? _c : null, handleChange: handleChange, options: options.map(function (opt) {
                            return (React.createElement(MenuItem, { key: opt.id, value: opt.id }, opt.name));
                        }) }),
                    React.createElement(Controller, { control: control, name: "id", defaultValue: id, render: function (_a) {
                            var field = _a.field;
                            return React.createElement(TextField, { hidden: true });
                        } }),
                    React.createElement(Controller, { control: control, name: "follow_up_date", defaultValue: followUpDate, render: function (_a) {
                            var field = _a.field;
                            return React.createElement(TextField, { hidden: true });
                        } }),
                    React.createElement(Controller, { control: control, name: "route", defaultValue: window.location.href, render: function (_a) {
                            var field = _a.field;
                            return React.createElement(TextField, { hidden: true });
                        } })),
                React.createElement(DialogActions, null,
                    React.createElement(Button, { onClick: handleClose, color: "primary" }, "Cancel"),
                    React.createElement(Button, { onClick: handleSubmit(submitUpdate), color: "primary" }, "Change"))))));
};
export default LeadSetDisposition;
