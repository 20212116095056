import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import NumberFormat from "react-number-format";
import { helperFunctions } from "../../../Helpers";
export var BrokerContactInfoCard = function (_a) {
    var _b, _c, _d, _e;
    var broker = _a.broker;
    return (React.createElement(Grid, { container: true },
        React.createElement(Grid, { item: true, xs: 12, style: { backgroundColor: "#F1F1F1", marginBottom: "14px" } },
            React.createElement(Box, { mx: 1 },
                React.createElement(Typography, { variant: "h6" }, "Broker Contact Info"))),
        React.createElement(Grid, { container: true, item: true, xs: 12 },
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2" }, "Phone: "))),
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } },
                    React.createElement(NumberFormat, { value: broker === null || broker === void 0 ? void 0 : broker.phone, displayType: "text", format: "(###) ###-####" }),
                    " "))),
        React.createElement(Grid, { container: true, item: true, xs: 12 },
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2" }, "Phone Alt: "))),
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } },
                    React.createElement(NumberFormat, { value: broker === null || broker === void 0 ? void 0 : broker.phone_alt, displayType: "text", format: "(###) ###-####" }),
                    " "))),
        React.createElement(Grid, { container: true, item: true, xs: 12 },
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2" }, "Email: "))),
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, (_c = (_b = broker === null || broker === void 0 ? void 0 : broker.user) === null || _b === void 0 ? void 0 : _b.email) !== null && _c !== void 0 ? _c : ""))),
        React.createElement(Grid, { container: true, item: true, xs: 12 },
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2" }, "NPN Number: "))),
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, (_d = broker.npn) !== null && _d !== void 0 ? _d : ""))),
        React.createElement(Grid, { container: true, item: true, xs: 12 },
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2" },
                        "Broker Since:",
                        " "))),
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, helperFunctions.getDateTime(broker.created_at)))),
        React.createElement(Grid, { container: true, item: true, xs: 12 },
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2" },
                        "Domicile State:",
                        " "))),
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, (_e = broker === null || broker === void 0 ? void 0 : broker.dom_state) === null || _e === void 0 ? void 0 : _e.name)))));
};
