import React, { useEffect, useState } from "react";
import { MenuItem, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/";
import GenericControllerDropdownInput from "./GenericControllerDropdownInput";
import GenericControllerCheckboxInput from "./GenericControllerCheckboxInput";
import GenericControllerTextInput from "./GenericControllerTextInput";
import Referrals from "./Referrals";
function addBusinessDays(originalDate, numDaysToAdd) {
    var Sunday = 0;
    var Saturday = 6;
    var daysRemaining = numDaysToAdd;
    var newDate = new Date(originalDate);
    while (daysRemaining > 0) {
        newDate.setDate(newDate.getDate() + 1);
        if (newDate.getDay() !== Sunday && newDate.getDay() !== Saturday) {
            daysRemaining--;
        }
    }
    return newDate;
}
var StatusContact = function (props) {
    var _a, _b, _c, _d;
    var _e = useState(props.data.status), status = _e[0], setStatus = _e[1];
    var activeDispositions = [2, 4, 5, 6, 8, 9, 10, 12, 13, 14, 19, 24];
    var control = props.control, watch = props.watch, errors = props.errors, data = props.data, referrals = props.referrals, dispositionGroup = props.dispositionGroup, dispositionId = props.dispositionId, setDispositionId = props.setDispositionId, resetField = props.resetField;
    var leadType = ((_a = data === null || data === void 0 ? void 0 : data.lead_type) === null || _a === void 0 ? void 0 : _a.id) || "0";
    var _f = useState((_b = data === null || data === void 0 ? void 0 : data.follow_up_date) !== null && _b !== void 0 ? _b : ""), followUpDate = _f[0], setFollowUpDate = _f[1];
    var handleFollowUp = function (e) {
        var date = new Date();
        var followUp = "";
        console.log(e);
        switch (e) {
            case "2":
            case "6":
                followUp = addBusinessDays(date, 2).toISOString().slice(0, 10);
                break;
            case "5":
            case "9":
            case "24":
                console.log("attempted first call");
                followUp = addBusinessDays(date, 1).toISOString().slice(0, 10);
                console.log(followUp);
                break;
            case "8":
            case "13":
                followUp = addBusinessDays(date, 7).toISOString().slice(0, 10);
                break;
            case "12":
                followUp = date.toISOString().slice(0, 10);
                break;
            case "14":
                followUp = addBusinessDays(date, 5).toISOString().slice(0, 10);
                break;
            default:
                break;
        }
        console.log(followUp);
        if (followUp !== "") {
            setFollowUpDate(followUp);
            resetField("follow_up_date", { defaultValue: followUp });
        }
        else {
            resetField("follow_up_date", { defaultValue: "" });
        }
    };
    useEffect(function () {
        if (activeDispositions.includes(parseInt(dispositionId))) {
            setStatus("Active");
        }
        else {
            setStatus("Closed");
        }
    }, [dispositionId]);
    return (React.createElement(Grid, { xs: 12, container: true, spacing: 2 },
        React.createElement(Grid, { xs: 12 },
            React.createElement(Grid, { xs: 12 },
                React.createElement(Typography, { variant: "h5", component: "h3", className: "section-title" }, "Status & Contact"),
                React.createElement(Typography, null,
                    "Status: ",
                    status)),
            React.createElement(Grid, { container: true, xs: 12 },
                React.createElement(Grid, { xs: 12, sm: 6, xl: 3 },
                    React.createElement(GenericControllerDropdownInput, { name: "disposition_id", id: "disposition_id", label: "Disposition", control: control, rules: { required: true }, defaultValue: dispositionId, error: "disposition_id" in errors, handleChange: function (e) {
                            setDispositionId(e.target.value);
                            handleFollowUp(e.target.value.toString());
                        }, options: dispositionGroup.map(function (disposition) {
                            if (leadType != 11 &&
                                disposition.id == "19") {
                                return null;
                            }
                            return (React.createElement(MenuItem, { key: disposition.id, value: parseInt(disposition.id) }, disposition.name));
                        }) })),
                [2, 4, 5, 6, 8, 9, 12, 13, 14, 20, 24].includes(parseInt(dispositionId)) && (React.createElement(Grid, { xs: 12, sm: 6, xl: 4 },
                    React.createElement(GenericControllerTextInput, { label: "Follow-up Date", type: "date", control: control, rules: { required: true }, name: "follow_up_date", defaultValue: followUpDate, customOnChange: setFollowUpDate, error: "follow_up_date" in errors, shrinkLabel: true }))),
                React.createElement(Grid, { xs: 12, sm: 6, xl: 4 },
                    React.createElement(GenericControllerCheckboxInput, { control: control, defaultChecked: (_c = (data === null || data === void 0 ? void 0 : data.permission_to_call) === 1) !== null && _c !== void 0 ? _c : "", name: "permission_to_call", label: "Permission to Call" })),
                React.createElement(Grid, { xs: 12, sm: 6, xl: 4 },
                    React.createElement(GenericControllerDropdownInput, { label: "Best time to reach", name: "best_time_call", id: "best_time_call", control: control, defaultValue: (_d = data === null || data === void 0 ? void 0 : data.best_time_call) !== null && _d !== void 0 ? _d : "", error: "best_time_call" in errors, options: [{ id: "AM" }, { id: "PM" }].map(function (time) {
                            return (React.createElement(MenuItem, { key: time.id, value: time.id }, time.id));
                        }) })),
                ["member"].includes(data.formType) && (React.createElement(Grid, { xs: 12 },
                    React.createElement(Referrals, { data: data, control: control, watch: watch, errors: errors, referrals: referrals })))))));
};
export default StatusContact;
