var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { MenuItem, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/";
import React, { useState } from "react";
import GenericControllerDropdownInput from "../../../components/GenericControllerDropdownInput";
import GenericNumberInput from "../../../components/GenericNumberInput";
import GenericRadioInput from "../../../components/GenericRadioInput";
import GenericControllerTextInput from "../../../components/GenericControllerTextInput";
import LifeQuestion from "./LifeQuestion";
var LifeIndividualQuestions = function (_a) {
    var _b, _c, _d, _e, _f;
    var lead = _a.lead, control = _a.control, errors = _a.errors;
    var _g = useState(lead === null || lead === void 0 ? void 0 : lead.extra_data_json), leadState = _g[0], setLeadState = _g[1];
    var handleStateChange = function (e) {
        console.log(leadState);
        console.log(e);
        var name = e.target.name;
        var key = name.substring(name.indexOf("[") + 1, name.length - 1);
        setLeadState(function (prevState) {
            var _a;
            return __assign(__assign({}, prevState), (_a = {}, _a[key] = e.target.value, _a));
        });
    };
    return (React.createElement(Grid, { container: true, spacing: 2, xs: 12 },
        React.createElement(Grid, { xs: 12 },
            React.createElement(Typography, { variant: "h5", component: "h3", className: "section-title" }, "Individual Questions")),
        React.createElement(Grid, { xs: 12, sm: 6 },
            React.createElement(GenericNumberInput, { prefix: "$", thousandSeparator: true, name: "extra_data_json[requested_amount_of_coverage]", label: "Requested Amount of Coverage", defaultValue: (_b = leadState === null || leadState === void 0 ? void 0 : leadState.requested_amount_of_coverage) !== null && _b !== void 0 ? _b : "", rules: { required: false }, error: errors.extra_data_json
                    ? "requested_amount_of_coverage" in
                        errors.extra_data_json
                    : false, control: control })),
        React.createElement(Grid, { xs: 12, sm: 6 },
            React.createElement(GenericControllerDropdownInput, { label: "Desired duration of policy", id: "duration-select", defaultValue: (_c = leadState === null || leadState === void 0 ? void 0 : leadState.desired_duration_of_policy) !== null && _c !== void 0 ? _c : "", control: control, InputLabelProps: { shrink: true }, name: "extra_data_json[desired_duration_of_policy]", error: errors.extra_data_json
                    ? "desired_duration_of_policy" in
                        errors.extra_data_json
                    : false, 
                // handleChange={handleStateChange}
                placeHolder: "Select Duration", options: [
                    "10 Years",
                    "15 Years",
                    "20 Years",
                    "30 Years",
                    "Lifetime",
                ].map(function (option) { return (React.createElement(MenuItem, { value: option, key: option.replace(/ /g, "") }, option)); }) }),
            " "),
        React.createElement(Grid, { xs: 12, sm: 6 },
            React.createElement(GenericRadioInput, { error: !!((_d = errors.extra_data_json) === null || _d === void 0 ? void 0 : _d.looking_to_replace_your_existing_policy), legend: "Are you looking to replace your existing policy?", name: "extra_data_json[looking_to_replace_your_existing_policy]", defaultValue: (leadState === null || leadState === void 0 ? void 0 : leadState.looking_to_replace_your_existing_policy) ==
                    true
                    ? "1"
                    : "0", rules: { required: true }, control: control })),
        React.createElement(Grid, { xs: 12, sm: 6 },
            React.createElement(GenericRadioInput, { error: !!((_e = errors.extra_data_json) === null || _e === void 0 ? void 0 : _e.applied_for_life_insurance_in_the_past_6_months), legend: "Have you applied for life insurance in the past 6\n                        months?", control: control, name: "extra_data_json[applied_for_life_insurance_in_the_past_6_months]", defaultValue: (leadState === null || leadState === void 0 ? void 0 : leadState.applied_for_life_insurance_in_the_past_6_months) ==
                    true
                    ? "1"
                    : "0", rules: { required: true } })),
        React.createElement(LifeQuestion, { legend: "Have you used any tobacco products in the past 5 years? (If yes, specify what type)", name: "tobacco", rules: { required: true }, leadState: leadState, handleStateChange: handleStateChange, errors: errors, control: control }),
        React.createElement(Grid, { xs: 12 },
            React.createElement("h4", { className: "section-title" }, "Have you been treated for any of these conditions?")),
        React.createElement(Grid, { container: true, xs: 12 },
            React.createElement(LifeQuestion, { legend: "Alcohol or substance abuse", name: "alcohol", rules: { required: true }, leadState: leadState, handleStateChange: handleStateChange, errors: errors, control: control }),
            React.createElement(LifeQuestion, { legend: "Asthma", name: "asthma", rules: { required: true }, leadState: leadState, handleStateChange: handleStateChange, errors: errors, control: control }),
            React.createElement(LifeQuestion, { legend: "Blood Pressure", name: "blood_pressure", rules: { required: true }, leadState: leadState, handleStateChange: handleStateChange, errors: errors, control: control }),
            React.createElement(LifeQuestion, { legend: "Cancer", name: "cancer", rules: { required: true }, leadState: leadState, handleStateChange: handleStateChange, errors: errors, control: control }),
            React.createElement(LifeQuestion, { legend: "Cholesterol", name: "cholesterol", rules: { required: true }, leadState: leadState, handleStateChange: handleStateChange, errors: errors, control: control }),
            React.createElement(LifeQuestion, { legend: "Depression or Anxiety", name: "depression_or_anxiety", rules: { required: true }, leadState: leadState, handleStateChange: handleStateChange, errors: errors, control: control }),
            React.createElement(LifeQuestion, { legend: "Diabetes", name: "diabetes", rules: { required: true }, leadState: leadState, handleStateChange: handleStateChange, errors: errors, control: control }),
            React.createElement(LifeQuestion, { legend: "Heart Issues", name: "heart", rules: { required: true }, leadState: leadState, handleStateChange: handleStateChange, errors: errors, control: control }),
            React.createElement(LifeQuestion, { legend: "Sleep Apnea", name: "sleep", rules: { required: true }, leadState: leadState, handleStateChange: handleStateChange, errors: errors, control: control })),
        React.createElement(Grid, { xs: 12, md: 6 },
            React.createElement(GenericControllerTextInput, { label: "What medications are you taking?", multiline: true, defaultValue: (_f = leadState === null || leadState === void 0 ? void 0 : leadState.medications) !== null && _f !== void 0 ? _f : "", control: control, name: "extra_data_json[medications]", error: false }))));
};
export default LifeIndividualQuestions;
