var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useState } from "react";
import { Button, Box, Modal, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, MenuItem, } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/";
import { router, Link, usePage } from "@inertiajs/react";
import { useForm } from "react-hook-form";
import { now, startCase } from "lodash";
import GenericControllerDropdownInput from "../../../components/GenericControllerDropdownInput";
import GenericControllerTextInput from "../../../components/GenericControllerTextInput";
import { hasPermission, agentHasRole } from "../../../utils/utils";
import route from "ziggy-js";
import { Alert } from "@mui/material";
var MemberAction = function (_a) {
    var _b, _c, _d, _e, _f;
    var member = _a.member, memberStatuses = _a.memberStatuses, memberLeaveReasons = _a.memberLeaveReasons;
    var _g = useState(member === null || member === void 0 ? void 0 : member.status), memberStatus = _g[0], setMemberStatus = _g[1];
    var _h = useState(member === null || member === void 0 ? void 0 : member.status), frontEndStatus = _h[0], setFrontEndStatus = _h[1];
    var _j = useState(false), openStatusUpdate = _j[0], setOpenStatusUpdate = _j[1];
    var _k = useState(false), openExpiration = _k[0], setOpenExpiration = _k[1];
    var _l = useState(false), openMemberSince = _l[0], setOpenMemberSince = _l[1];
    var _m = useState(false), clearLeaveReason = _m[0], setClearLeaveReason = _m[1];
    var _o = useState((_b = member === null || member === void 0 ? void 0 : member.member_leave_reason_id) !== null && _b !== void 0 ? _b : ""), memberLeaveReasonId = _o[0], setMemberLeaveReasonId = _o[1];
    var _p = useState(false), open = _p[0], setOpen = _p[1];
    var auth = usePage().props.auth;
    var frontEndStatuses = __spreadArray(__spreadArray([], memberStatuses, true), ["cancelled"], false);
    var _q = useForm({
        shouldUnregister: true,
    }), handleSubmit = _q.handleSubmit, formErrors = _q.formState.errors, control = _q.control, watch = _q.watch;
    var watchPassword = watch("password", "");
    var watchPasswordConfirm = watch("password_confirm", "");
    var handleStatusUpdate = function () {
        setOpenStatusUpdate(true);
    };
    var handleResetPassword = function () {
        setOpen(true);
    };
    var handleExpirationChange = function () {
        setOpenExpiration(true);
    };
    var handleMemberSinceChange = function () {
        setOpenMemberSince(true);
    };
    var handleClose = function () {
        setOpen(false);
    };
    var handleCloseStatusUpdate = function () {
        setOpenStatusUpdate(false);
    };
    var handleCloseExpiration = function () {
        setOpenExpiration(false);
    };
    var handleCloseMemberSince = function () {
        setOpenMemberSince(false);
    };
    var handleMemberLeaveReasonChange = function (e) {
        setMemberLeaveReasonId(e.target.value);
    };
    var handleMemberStatusChange = function (e) {
        if (e.target.value === "cancelled") {
            setClearLeaveReason(false);
            setMemberStatus("deleted");
            setFrontEndStatus(e.target.value);
            return;
        }
        setClearLeaveReason(true);
        setMemberStatus(e.target.value);
        setFrontEndStatus(e.target.value);
    };
    var handleSendCard = function () {
        router.post("/member/send-card", { id: member.id });
    };
    var handleSendText = function () {
        router.post("/member/send-text", { id: member.id });
    };
    var handleSendEmail = function () {
        router.post("/member/send-email", { id: member.id });
    };
    var handleSendSelfEmail = function () {
        router.post("/member/send-self-email", {
            id: member.id,
            email: auth.user.email,
        });
    };
    var handleSendLtcCrossSell = function () {
        router.post("/member/send-ltc-cross-sell", {
            id: member.id,
        });
    };
    var handleResetEmail = function () {
        router.post("/member/send-reset-email", {
            email: member.email,
            id: member.id,
        });
    };
    var submitStatusUpdate = function () {
        setOpenStatusUpdate(false);
        router.post("/member/status-update", {
            id: member.id,
            status: memberStatus,
            member_leave_reason_id: !clearLeaveReason
                ? memberLeaveReasonId
                : "",
        });
    };
    var submitResetPassword = function (data) {
        setOpen(false);
        router.post("/member/reset-password", {
            email: member.email,
            id: member.id,
            password: data.password,
            password_confirmation: data.password_confirm,
        });
    };
    var submitExpirationUpdate = function (data) {
        setOpenExpiration(false);
        router.post("/member/set-expiration-date", {
            id: member.id,
            member_expiration_date: data.member_expiration_date,
        });
    };
    var submitMemberSince = function (data) {
        setOpenMemberSince(false);
        router.post("/member/set-member-since", {
            id: member.id,
            member_since: data.member_since,
        });
    };
    return (React.createElement(Grid, { container: true, spacing: 1, xs: 12, justifyContent: "flex-end", alignItems: "flex-end" },
        React.createElement(Grid, { container: true, xs: 12, justifyContent: "flex-end", alignItems: "flex-end" },
            React.createElement(Box, { mr: 1 }, hasPermission(auth, "edit members") && (React.createElement(Link, { href: route("edit_member", [member.id]), as: "span", style: {
                    textDecoration: "none",
                } },
                React.createElement(Button, { variant: "contained", style: {
                        backgroundColor: "#392869",
                        color: "#FFF",
                        fontSize: "11px",
                        fontWeight: "400",
                    }, size: "small" }, agentHasRole(auth, "action_organizer")
                    ? "Edit / Donate"
                    : "Edit / Renew")))),
            !agentHasRole(auth, "action_organizer") && (React.createElement(React.Fragment, null,
                React.createElement(Box, { mr: 1 },
                    React.createElement(Button, { variant: "contained", onClick: handleSendText, size: "small", style: { fontSize: "11px", fontWeight: "400" } }, "Send Text")),
                React.createElement(Box, { mr: 1 },
                    React.createElement(Button, { variant: "contained", style: {
                            backgroundColor: "#F2A22F",
                            color: "#FFF",
                            fontSize: "11px",
                            fontWeight: "400",
                        }, onClick: handleSendCard, size: "small" }, "Mail Cards")),
                React.createElement(Box, { mr: 1 },
                    React.createElement(Button, { variant: "contained", style: {
                            backgroundColor: "#1871BF",
                            color: "#FFF",
                            fontSize: "11px",
                            fontWeight: "400",
                        }, onClick: handleSendEmail, size: "small" }, "Email Cards")),
                React.createElement(Box, { mr: 1 },
                    React.createElement(Button, { variant: "contained", style: {
                            backgroundColor: "#3EBF3E",
                            color: "#FFF",
                            fontSize: "11px",
                            fontWeight: "400",
                        }, onClick: handleSendSelfEmail, size: "small" }, "Email Myself")))),
            (agentHasRole(auth, "msr") || agentHasRole(auth, "admin")) && (React.createElement(Box, { mr: 1 },
                React.createElement(Button, { variant: "contained", style: {
                        backgroundColor: "#F1221C",
                        color: "#FFF",
                        fontSize: "11px",
                        fontWeight: "400",
                    }, onClick: handleStatusUpdate, size: "small" }, "Update Status")))),
        React.createElement(Grid, { container: true, xs: 12, justifyContent: "flex-end", alignContent: "flex-end", mt: 1 },
            React.createElement(Box, { mr: 1 }, hasPermission(auth, "view recordings") && (React.createElement(Link, { as: "span", href: route("show_recordings", { id: member.id }), method: "post", data: { id: member.id }, style: {
                    textDecoration: "none",
                } },
                React.createElement(Button, { variant: "contained", style: {
                        backgroundColor: "#392869",
                        color: "#FFF",
                        fontSize: "11px",
                        fontWeight: "400",
                    }, size: "small" }, "Recordings")))),
            React.createElement(Box, { mr: 1 },
                !agentHasRole(auth, "action_organizer") && (React.createElement(Button, { variant: "contained", onClick: handleResetEmail, size: "small", style: { fontSize: "11px", fontWeight: "400" } }, "Forgot Password")),
                React.createElement(Modal, { open: openStatusUpdate, onClose: handleCloseStatusUpdate },
                    React.createElement(Dialog, { open: openStatusUpdate, onClose: handleCloseStatusUpdate },
                        React.createElement(DialogTitle, null, "Member Status"),
                        React.createElement(DialogContent, { style: { minWidth: "500px" } },
                            React.createElement(Grid, { container: true, xs: 12, spacing: 1 },
                                React.createElement(GenericControllerDropdownInput, { formControlSX: { my: 1 }, name: "status", fullWidth: true, control: control, label: "Member Status", id: "member-status-label", defaultValue: frontEndStatus, handleChange: handleMemberStatusChange, rules: { required: true }, options: frontEndStatuses.map(function (status) {
                                        return (React.createElement(MenuItem, { key: status, value: status }, status === "deleted"
                                            ? "Inactive"
                                            : startCase(status)));
                                    }) }),
                                frontEndStatus == "cancelled" ? (React.createElement(GenericControllerDropdownInput, { formControlSX: { mt: 1 }, name: "member_leave_reason_id", rules: {
                                        required: clearLeaveReason,
                                        validate: function (value) {
                                            return value > 0;
                                        },
                                    }, label: "Member Leave Reason", error: "member_leave_reason_id" in
                                        formErrors, control: control, id: "member-leave-reason-select", defaultValue: String(memberLeaveReasonId), handleChange: handleMemberLeaveReasonChange, placeHolder: "Select Reason", placeHolderValue: 0, options: memberLeaveReasons.map(function (memberLeaveReason) {
                                        console.log(frontEndStatus);
                                        return (React.createElement(MenuItem, { key: memberLeaveReason.id, value: memberLeaveReason.id }, memberLeaveReason.text));
                                    }) })) : null)),
                        React.createElement(DialogActions, null,
                            React.createElement(Button, { onClick: handleCloseStatusUpdate, color: "primary", size: "small" }, "Cancel"),
                            React.createElement(Button, { onClick: handleSubmit(submitStatusUpdate), color: "primary" }, "Change"))))),
            !agentHasRole(auth, "action_organizer") && (React.createElement(Box, { mr: 1 },
                React.createElement(Button, { variant: "contained", style: {
                        backgroundColor: "#F2A22F",
                        color: "#FFF",
                        fontSize: "11px",
                        fontWeight: "400",
                    }, onClick: handleResetPassword, size: "small" }, "Reset Password"),
                React.createElement(Modal, { open: open, onClose: handleClose, "aria-labelledby": "simple-modal-title", "aria-describedby": "simple-modal-description" },
                    React.createElement(Dialog, { open: open, onClose: handleClose, "aria-labelledby": "form-dialog-title" },
                        React.createElement(DialogTitle, { id: "form-dialog-title" }, "Change Password"),
                        React.createElement(DialogContent, null,
                            React.createElement(DialogContentText, null, "Please provide a password that contains: number, uppercase, lowercase and is at least 8 characters long."),
                            "password" in formErrors && (React.createElement(Alert, { severity: "error" }, (_d = (_c = formErrors.password) === null || _c === void 0 ? void 0 : _c.message) === null || _d === void 0 ? void 0 : _d.toString())),
                            React.createElement(GenericControllerTextInput, { autoFocus: true, margin: "dense", name: "password", rules: {
                                    required: true,
                                    pattern: {
                                        value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&]?)[A-Za-z\d$@$!%*?&]{8,}/,
                                        message: "Don't match pattern above",
                                    },
                                    validate: {
                                        match: function (value) {
                                            return value ===
                                                watchPasswordConfirm ||
                                                "Password Mismatch";
                                        },
                                    },
                                }, defaultValue: "", id: "password", label: "New Password", type: "password", error: "password" in formErrors, control: control, fullWidth: true }),
                            React.createElement(GenericControllerTextInput, { margin: "dense", name: "password_confirm", rules: {
                                    required: true,
                                    pattern: {
                                        value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&]?)[A-Za-z\d$@$!%*?&]{8,}/,
                                        message: "Don't match pattern above",
                                    },
                                    validate: {
                                        match: function (value) {
                                            return value === watchPassword ||
                                                "Password Mismatch";
                                        },
                                    },
                                }, defaultValue: "", control: control, id: "password_confirm", label: "Confirm Password", type: "password", error: "password_confirm" in formErrors, fullWidth: true })),
                        React.createElement(DialogActions, null,
                            React.createElement(Button, { onClick: handleClose, color: "primary" }, "Cancel"),
                            React.createElement(Button, { onClick: handleSubmit(submitResetPassword), color: "primary" }, "Change")))))),
            hasPermission(auth, "edit member-expiration") && (React.createElement(Box, { mr: 1 },
                React.createElement(Button, { variant: "contained", style: {
                        backgroundColor: "#1871BF",
                        color: "#FFF",
                        fontSize: "11px",
                        fontWeight: "400",
                    }, onClick: handleExpirationChange, size: "small" }, "Change Expiration"),
                React.createElement(Modal, { open: openExpiration, onClose: handleCloseExpiration },
                    React.createElement(Dialog, { open: openExpiration, onClose: handleCloseExpiration },
                        React.createElement(DialogTitle, { id: "form-dialog-title" }, "Set Expiration Date"),
                        React.createElement(DialogContent, null,
                            React.createElement(GenericControllerTextInput, { label: "Expiration Date", type: "date", name: "member_expiration_date", control: control, defaultValue: (_e = member === null || member === void 0 ? void 0 : member.member_expiration_date) !== null && _e !== void 0 ? _e : now(), error: "member_expiration_date" in
                                    formErrors, shrinkLabel: true, fullWidth: true })),
                        React.createElement(DialogActions, null,
                            React.createElement(Button, { onClick: handleCloseExpiration, color: "primary" }, "Cancel"),
                            React.createElement(Button, { onClick: handleSubmit(submitExpirationUpdate), color: "primary" }, "Change")))))),
            hasPermission(auth, "edit member-since") && (React.createElement(Box, { mr: 1 },
                React.createElement(Button, { variant: "contained", style: {
                        backgroundColor: "#3EBF3E",
                        color: "#FFF",
                        fontSize: "11px",
                        fontWeight: "400",
                    }, onClick: handleMemberSinceChange, size: "small" }, "Change Member Since"),
                React.createElement(Modal, { open: openMemberSince, onClose: handleCloseMemberSince },
                    React.createElement(Dialog, { open: openMemberSince, onClose: handleCloseMemberSince },
                        React.createElement(DialogTitle, { id: "form-dialog-title" }, "Set Member Since"),
                        React.createElement(DialogContent, null,
                            React.createElement(GenericControllerTextInput, { label: "Member Since", type: "date", control: control, name: "member_since", defaultValue: (_f = member === null || member === void 0 ? void 0 : member.member_since) !== null && _f !== void 0 ? _f : now(), error: "member_since" in formErrors, shrinkLabel: true, fullWidth: true })),
                        React.createElement(DialogActions, null,
                            React.createElement(Button, { onClick: handleCloseMemberSince, color: "primary" }, "Cancel"),
                            React.createElement(Button, { onClick: handleSubmit(submitMemberSince), color: "primary" }, "Change")))))),
            hasPermission(auth, "send ltc-cross-sell") && (React.createElement(Box, { mr: 1 },
                React.createElement(Button, { variant: "contained", style: {
                        backgroundColor: "#F1221C",
                        color: "#FFF",
                        fontSize: "11px",
                        fontWeight: "400",
                    }, onClick: handleSendLtcCrossSell, size: "small" }, "Send LTC Cross Sell"))))));
};
export default MemberAction;
