import { Link, usePage } from "@inertiajs/react";
import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import route from "ziggy-js";
import PageTitle from "../../components/PageTitle";
import { hasPermission } from "../../utils/utils";
import Layout from "../Layout";
import BrokerNav from "./components/BrokerNav";
var AbsCarriers = function (_a) {
    var _b, _c;
    var response_data = _a.response_data;
    var auth = usePage().props.auth;
    var _d = useState(true), menuOpen = _d[0], toggleMenuOpen = _d[1];
    var broker = response_data.broker, carrier_count = response_data.carrier_count, lead_count = response_data.lead_count, document_count = response_data.document_count, abs_carriers = response_data.abs_carriers, license_count = response_data.license_count;
    return (React.createElement(Layout, { title: "Member Info", menuOpen: menuOpen, toggleMenuOpen: toggleMenuOpen },
        React.createElement(Grid, { container: true, spacing: 2 },
            React.createElement(Grid, { container: true, item: true, xs: 12, alignItems: "center", justifyContent: "center" },
                React.createElement(BrokerNav, { broker: broker, lead_count: lead_count, document_count: document_count, carrier_count: carrier_count, license_count: license_count })),
            React.createElement(Grid, { item: true, xs: 12 },
                React.createElement(PageTitle, { title: "Carriers" })),
            hasPermission(auth, "assign broker-carriers") && (React.createElement(Grid, { item: true, xs: 12, md: 6, lg: 3 },
                React.createElement(Button, { component: Link, type: "button", variant: "outlined", fullWidth: true, color: "secondary", href: route("abs_assign_carrier", [
                        (_b = broker === null || broker === void 0 ? void 0 : broker.user) === null || _b === void 0 ? void 0 : _b.id,
                    ]).toString() }, "Assign Carrier"))),
            hasPermission(auth, "add broker-carriers") && (React.createElement(Grid, { item: true, xs: 12, md: 6, lg: 3 },
                React.createElement(Button, { component: Link, type: "button", variant: "outlined", fullWidth: true, color: "secondary", href: route("abs_add_carrier", [
                        (_c = broker === null || broker === void 0 ? void 0 : broker.user) === null || _c === void 0 ? void 0 : _c.id,
                    ]).toString() }, "Add Carrier"))),
            React.createElement(Grid, { item: true, xs: 12 }, Object.keys(abs_carriers).map(function (el) { return (React.createElement(Box, { key: el, mb: 2 },
                React.createElement(Typography, { variant: "h6" }, el),
                React.createElement(Grid, { container: true, spacing: 2 }, abs_carriers[el].map(function (el) { return (React.createElement(Grid, { item: true, key: el.id, xs: 3 },
                    React.createElement(Button, { variant: "outlined", fullWidth: true }, "".concat(el.product, " / ").concat(el.name)))); })))); })))));
};
export default AbsCarriers;
