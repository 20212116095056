import { Box, Paper, Typography, Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useEffect, useState } from "react";
import Layout from "../Layout";
import { MemberNavWrapper } from "./components/MemberNavWrapper";
import MemberHeader from "./components/MemberHeader";
import MemberDonation from "./components/MemberDonation";
import DonationNoteCard from "./components/DonationNoteCard";
import MemberDonationsOverview from "./components/MemberDonationsOverview";
import { Link } from "@inertiajs/react";
import { filterDeletedLeads, getShowDeleted } from "../../utils/utils";
import MemberLead from "./components/MemberLead";
import MemberDocument from "./components/MemberDocument";
var MemberDonations = function (_a) {
    var _b, _c, _d, _e;
    var response_data = _a.response_data, auth = _a.auth;
    var _f = useState(true), menuOpen = _f[0], toggleMenuOpen = _f[1];
    var _g = useState(function () { return getShowDeleted(); }), showDeleted = _g[0], setShowDeleted = _g[1];
    var member = response_data.member, all_documents = response_data.all_documents, donation_documents = response_data.donation_documents, lead_types = response_data.lead_types, agents = response_data.agents, states = response_data.states, dispositions = response_data.dispositions;
    useEffect(function () {
        localStorage.setItem("showDeleted", JSON.stringify(showDeleted));
    }, [showDeleted]);
    return (React.createElement(Layout, { title: "Member Info", menuOpen: menuOpen, toggleMenuOpen: toggleMenuOpen },
        React.createElement(Grid, { container: true },
            React.createElement(Grid, { container: true, xs: 12, justifyContent: "center", alignContent: "center", spacing: 4 },
                React.createElement(MemberNavWrapper, { member: member, all_documents: all_documents }))),
        React.createElement(Grid, { container: true, m: 1, p: 1, xs: 12 },
            React.createElement(Grid, { container: true, xs: 12 },
                React.createElement(Grid, { xs: 3 },
                    React.createElement(MemberHeader, { member: member })),
                React.createElement(Grid, { my: 2, xs: 9 },
                    React.createElement(Paper, null,
                        React.createElement(MemberDonationsOverview, { member: member })))),
            React.createElement(Grid, { container: true, xs: 8 },
                React.createElement(Grid, { xs: 12 },
                    React.createElement(Grid, { mb: 1, container: true, xs: 12 },
                        React.createElement(Grid, { xs: 6 },
                            React.createElement(Typography, { variant: "h5" }, "Leads")),
                        React.createElement(Grid, { xs: 6, display: "flex", justifyContent: "end" },
                            React.createElement(Link, { href: "/lead/form/".concat(member.id, "/16") },
                                React.createElement(Button, { variant: "contained", style: {
                                        backgroundColor: "#286941",
                                        color: "#FFF",
                                        fontSize: "11px",
                                        fontWeight: "400",
                                    }, size: "small" }, "Add Lead")))), (_d = (_c = (_b = member === null || member === void 0 ? void 0 : member.leads) === null || _b === void 0 ? void 0 : _b.filter(function (el) { return el.lead_type_id == 16; })) === null || _c === void 0 ? void 0 : _c.filter(function (el) {
                    return filterDeletedLeads(el, showDeleted);
                })) === null || _d === void 0 ? void 0 :
                    _d.map(function (lead) { return (React.createElement(Box, { mb: 1, key: lead.id, id: "lead_".concat(lead.id) },
                        React.createElement(Paper, null,
                            React.createElement(MemberLead, { member: member, lead: lead, leadTypes: lead_types, agents: agents, states: states, dispositions: dispositions })))); })),
                React.createElement(Grid, { xs: 12 },
                    React.createElement(Box, { mb: 1, mt: 2 },
                        React.createElement(Typography, { variant: "h5" }, "Donations")), (_e = member === null || member === void 0 ? void 0 : member.donations) === null || _e === void 0 ? void 0 :
                    _e.map(function (donation) { return (React.createElement(Box, { mb: 1, key: donation.id, id: "donation_".concat(donation.id) },
                        React.createElement(Paper, null,
                            React.createElement(MemberDonation, { member: member, donation: donation })))); }))),
            React.createElement(Grid, { container: true, alignSelf: "start", xs: 4 },
                React.createElement(Grid, { xs: 12 },
                    React.createElement(Box, { my: 1, ml: 1 },
                        React.createElement(DonationNoteCard, { member: member }))),
                React.createElement(Grid, { xs: 12 },
                    React.createElement(Box, { my: 1, ml: 1 },
                        React.createElement(MemberDocument, { data: donation_documents })))))));
};
export default MemberDonations;
