import { Link } from "@inertiajs/react";
import { Button, Grid } from "@mui/material";
import React from "react";
import route from "ziggy-js";
var MemberButton = function (_a) {
    var member = _a.member, handleSubmit = _a.handleSubmit, onSubmit = _a.onSubmit;
    return (React.createElement(React.Fragment, null,
        React.createElement(Grid, { item: true, container: true, alignItems: "center", justifyContent: "center", spacing: 2 },
            React.createElement(Grid, { item: true, xs: 12, md: 6, lg: 3 },
                React.createElement(Button, { type: "submit", variant: "contained", color: "primary", onClick: handleSubmit(onSubmit), fullWidth: true }, (member === null || member === void 0 ? void 0 : member.id) != null ? "Update Member" : "Add Member")),
            React.createElement(Grid, { item: true, xs: 12, md: 6, lg: 3 },
                React.createElement(Button, { component: Link, type: "button", variant: "outlined", fullWidth: true, color: "secondary", href: (member === null || member === void 0 ? void 0 : member.id) == null
                        ? "/"
                        : route("show_member", [member.id]).toString() }, "Cancel")))));
};
export default MemberButton;
