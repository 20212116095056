import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import NotesForm from "../../../components/NotesForm";
import { BrokerNotes } from "./BrokerNotes";
export var BrokerNoteCard = function (_a) {
    var broker = _a.broker;
    var _b = useState(false), addNotesFlag = _b[0], setAddNotesFlag = _b[1];
    var handleAddNotesFlag = function () {
        setAddNotesFlag(!addNotesFlag);
    };
    return (React.createElement(Grid, { container: true },
        React.createElement(Grid, { item: true, xs: 12, style: { backgroundColor: "#F1F1F1", marginBottom: "14px" } },
            React.createElement(Box, { mx: 1 },
                React.createElement(Grid, { container: true },
                    React.createElement(Grid, { item: true, xs: 6 },
                        React.createElement(Typography, { variant: "h6" }, "Notes")),
                    React.createElement(Grid, { item: true, container: true, xs: 6, justifyContent: "flex-end", alignContent: "center" }, !addNotesFlag && (React.createElement(Button, { type: "button", onClick: handleAddNotesFlag, variant: "outlined", color: "primary", size: "small", style: {
                            fontSize: "10px",
                            fontWeight: "bold",
                            backgroundColor: "#1871BF",
                            color: "#FFF",
                        } }, "+ Note")))))),
        addNotesFlag ? (React.createElement(NotesForm, { noteOwner: broker, noteType: "broker", handleAddNotesFlag: handleAddNotesFlag })) : (React.createElement(Grid, { item: true, container: true, justifyContent: "center" },
            React.createElement(BrokerNotes, { notes: broker.abs_broker_notes })))));
};
