import { Grid } from "@mui/material";
import React, { useState } from "react";
import Layout from "../Layout";
import BrokerDetail from "./components/BrokerDetail";
import BrokerNav from "./components/BrokerNav";
var AbsMain = function (_a) {
    var response_data = _a.response_data, auth = _a.auth, flash = _a.flash;
    var _b = useState(true), menuOpen = _b[0], toggleMenuOpen = _b[1];
    var broker = response_data.broker, carrier_count = response_data.carrier_count, lead_count = response_data.lead_count, document_count = response_data.document_count, license_count = response_data.license_count;
    return (React.createElement(Layout, { title: "Member Info", menuOpen: menuOpen, toggleMenuOpen: toggleMenuOpen },
        React.createElement(Grid, { container: true, spacing: 2 },
            React.createElement(Grid, { container: true, item: true, xs: 12, alignItems: "center", justifyContent: "center" },
                React.createElement(BrokerNav, { broker: broker, lead_count: lead_count, document_count: document_count, license_count: license_count, carrier_count: carrier_count })),
            React.createElement(Grid, { item: true, xs: 12 },
                React.createElement(BrokerDetail, { broker: broker })))));
};
export default AbsMain;
