import React from "react";
// import { Grid } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import GenericControllerTextInput from "./GenericControllerTextInput";
var GenericNameForm = function (props) {
    var _a, _b, _c, _d, _e, _f;
    var control = props.control, member = props.member, errors = props.errors, options = props.options, _g = props.data, data = _g === void 0 ? {} : _g;
    return (React.createElement(Grid, { xs: 12, container: true, spacing: 2 },
        React.createElement(Grid, { xs: 12, md: 4 },
            React.createElement(GenericControllerTextInput, { control: control, label: options.first_name.label, name: options.first_name[data.formType], rules: {
                    required: options.first_name.required,
                }, defaultValue: (_b = (_a = data === null || data === void 0 ? void 0 : data[options.first_name[data.formType]]) !== null && _a !== void 0 ? _a : member[options.first_name["name"]]) !== null && _b !== void 0 ? _b : "", error: options.first_name.name in errors, autoFocus: options.first_name.focus })),
        React.createElement(Grid, { xs: 12, md: 4 },
            React.createElement(GenericControllerTextInput, { control: control, label: options.middle_name.label, name: options.middle_name[data.formType], rules: { required: options.middle_name.required }, defaultValue: (_d = (_c = data === null || data === void 0 ? void 0 : data[options.middle_name[data.formType]]) !== null && _c !== void 0 ? _c : member[options.middle_name["name"]]) !== null && _d !== void 0 ? _d : "", error: options.middle_name.name in errors })),
        React.createElement(Grid, { xs: 12, md: 4 },
            React.createElement(GenericControllerTextInput, { control: control, label: options.last_name.label, name: options.last_name[data.formType], rules: { required: options.last_name.required }, defaultValue: (_f = (_e = data === null || data === void 0 ? void 0 : data[options.last_name[data.formType]]) !== null && _e !== void 0 ? _e : member[options.last_name["name"]]) !== null && _f !== void 0 ? _f : "", error: options.last_name.name in errors }))));
};
export default GenericNameForm;
