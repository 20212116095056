import React from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import LeadMemberInfoCard from "./LeadMemberInfoCard";
import { hasAccessTo, hasPermission } from "../../../utils/utils";
import { Link, usePage } from "@inertiajs/react";
import route from "ziggy-js";
import PolicyInfoCard from "./PolicyInfoCard";
import PolicyNoteCard from "./PolicyNoteCard";
import PolicyDocuments from "./PolicyDocuments";
import GenericDeleteButton from "../../../components/GenericDeleteButton";
var useStyles = makeStyles(function (theme) {
    return createStyles({
        appRow: {
            paddingTop: ".75em",
        },
    });
});
var PolicyDetails = function (_a) {
    var member = _a.member, policy = _a.policy, leadTypes = _a.leadTypes, states = _a.states, insuranceCarriers = _a.insuranceCarriers;
    var auth = usePage().props.auth;
    var classes = useStyles();
    return (React.createElement(Grid, { container: true, style: {
            borderLeft: "1px #035399 solid",
            borderRight: "1px #035399 solid",
            borderBottom: "1px #035399 solid",
            borderTop: "1px #035399 solid",
            padding: "5px",
            borderRadius: "3px",
        } },
        React.createElement(Grid, { item: true, xs: 4, className: classes.appRow },
            React.createElement(LeadMemberInfoCard, { member: member, lead: policy, states: states })),
        React.createElement(Grid, { item: true, xs: 4, className: classes.appRow },
            React.createElement(PolicyInfoCard, { data: policy, insuranceCarriers: insuranceCarriers })),
        React.createElement(Grid, { item: true, xs: 4, className: classes.appRow },
            React.createElement(PolicyNoteCard, { data: policy, noteType: "policy" })),
        React.createElement(Grid, { item: true, xs: 4, className: classes.appRow },
            React.createElement(PolicyDocuments, { data: policy, fileType: "policies" })),
        React.createElement(Grid, { item: true, xs: 4, className: classes.appRow },
            React.createElement(Grid, { container: true, justifyContent: "center", alignContent: "center" },
                React.createElement(Grid, { item: true, xs: 12 },
                    React.createElement(Box, { mx: 1, mt: 3 },
                        React.createElement(Typography, { variant: "body1", style: { fontWeight: "bold" } }, "Options"))),
                hasAccessTo(auth, policy) &&
                    hasPermission(auth, "edit policies") && (React.createElement(Grid, { item: true, xs: 12 },
                    React.createElement(Box, { m: 1 },
                        React.createElement(Link, { href: route("edit_policy_form", [
                                policy.id,
                            ]), as: "span" },
                            React.createElement(Button, { variant: "outlined", color: "secondary", fullWidth: true }, "Edit Policy"))))),
                hasPermission(auth, "delete policies") && (React.createElement(Grid, { item: true, xs: 12 },
                    React.createElement(Box, { m: 1 },
                        React.createElement(GenericDeleteButton, { deleteRoute: '/policy/delete/' + policy.id, label: "policy", variant: "contained", id: policy.id }))))))));
};
export default PolicyDetails;
