import { Grid } from "@mui/material";
import React, { useState } from "react";
import FileUploadForm from "../../components/FileUploadForm";
import { hasPermission } from "../../utils/utils";
import Layout from "../Layout";
import BrokerDocumentTable from "./components/BrokerDocumentTable";
import BrokerNav from "./components/BrokerNav";
var AbsDocuments = function (_a) {
    var response_data = _a.response_data, auth = _a.auth, flash = _a.flash;
    var _b = useState(true), menuOpen = _b[0], toggleMenuOpen = _b[1];
    var broker = response_data.broker, carrier_count = response_data.carrier_count, lead_count = response_data.lead_count, document_count = response_data.document_count, documents = response_data.documents, license_count = response_data.license_count;
    return (React.createElement(Layout, { title: "Member Info", menuOpen: menuOpen, toggleMenuOpen: toggleMenuOpen },
        React.createElement(Grid, { container: true, spacing: 2 },
            React.createElement(Grid, { container: true, item: true, xs: 12, alignItems: "center", justifyContent: "center" },
                React.createElement(BrokerNav, { broker: broker, lead_count: lead_count, document_count: document_count, carrier_count: carrier_count, license_count: license_count })),
            React.createElement(Grid, { item: true, xs: 12 },
                hasPermission(auth, "add broker-documents") && (React.createElement(FileUploadForm, { memberId: broker.user.id, userId: broker.user.id, name: "abs_brokers", id: broker.user.id })),
                React.createElement(BrokerDocumentTable, { data: documents })))));
};
export default AbsDocuments;
