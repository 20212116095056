import React from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import LeadMemberInfoCard from "./LeadMemberInfoCard";
import LeadInfoCard from "./LeadInfoCard";
import LeadNoteCard from "./LeadNoteCard";
import LeadExtraInfo from "./LeadExtraInfo";
import LeadDocuments from "./LeadDocuments";
import SetTransferUserId from "../../../components/SetTransferUserId";
import { hasAccessTo, hasPermission } from "../../../utils/utils";
import { Link, usePage } from "@inertiajs/react";
import route from "ziggy-js";
import GenericSetDate from "../../../components/GenericSetDate";
import LeadSetDisposition from "./LeadSetDisposition";
import GenericDeleteButton from "../../../components/GenericDeleteButton";
var useStyles = makeStyles(function (theme) {
    return createStyles({
        leadRow: {
            paddingTop: ".75em",
        },
    });
});
var MemberLeadDetail = function (_a) {
    var member = _a.member, lead = _a.lead, agents = _a.agents, states = _a.states, dispositions = _a.dispositions;
    var auth = usePage().props.auth;
    var classes = useStyles();
    var leadDispositionGroupIds = [1, 2];
    return (React.createElement(Grid, { container: true, style: {
            borderLeft: "1px #035399 solid",
            borderRight: "1px #035399 solid",
            borderBottom: "1px #035399 solid",
            borderTop: "1px #035399 solid",
            padding: "5px",
            borderRadius: "3px",
        } },
        React.createElement(Grid, { item: true, xs: 4, className: classes.leadRow },
            React.createElement(LeadMemberInfoCard, { member: member, lead: lead, states: states })),
        React.createElement(Grid, { item: true, xs: 4, className: classes.leadRow },
            React.createElement(LeadInfoCard, { lead: lead })),
        React.createElement(Grid, { item: true, xs: 4, className: classes.leadRow },
            React.createElement(LeadNoteCard, { lead: lead })),
        React.createElement(Grid, { item: true, xs: 4, className: classes.leadRow },
            React.createElement(LeadExtraInfo, { extraInfo: lead.extra_data_json, lead: lead })),
        React.createElement(Grid, { item: true, xs: 4, className: classes.leadRow },
            React.createElement(LeadDocuments, { lead: lead })),
        React.createElement(Grid, { item: true, xs: 4, className: classes.leadRow },
            React.createElement(Grid, { container: true, justifyContent: "center", alignContent: "center" },
                React.createElement(Grid, { item: true, xs: 12 },
                    React.createElement(Box, { mx: 1, mt: 3 },
                        React.createElement(Typography, { variant: "body1", style: { fontWeight: "bold" } }, "Lead Options"))),
                React.createElement(Grid, { item: true, xs: 12 },
                    React.createElement(Box, { m: 1 },
                        React.createElement(SetTransferUserId, { data: lead, member: member, uri: "/lead/transfer-user-id-update", agents: agents, appType: false })),
                    (lead === null || lead === void 0 ? void 0 : lead.disposition_id) == 2 &&
                        (lead === null || lead === void 0 ? void 0 : lead.status) !== "applied" &&
                        hasAccessTo(auth, lead) &&
                        hasPermission(auth, "edit apps") && (React.createElement(Box, { m: 1 },
                        React.createElement(Link, { href: route("app_form", [lead.id]), as: "span" },
                            React.createElement(Button, { variant: "contained", fullWidth: true, size: "small", style: {
                                    backgroundColor: "#3EBF3E",
                                    color: "#FFF",
                                } }, "Create App")))),
                    (lead === null || lead === void 0 ? void 0 : lead.status) !== "applied" &&
                        hasAccessTo(auth, lead) &&
                        hasPermission(auth, "edit leads") && (React.createElement(Box, { m: 1 },
                        React.createElement(Link, { href: route("edit_lead_form", [
                                lead.id,
                            ]), as: "span" },
                            React.createElement(Button, { variant: "outlined", color: "primary", fullWidth: true, size: "small" }, "Edit Lead")))),
                    (lead === null || lead === void 0 ? void 0 : lead.status) !== "applied" &&
                        hasPermission(auth, "delete leads") && (React.createElement(Box, { m: 1 },
                        React.createElement(GenericDeleteButton, { deleteRoute: '/lead/delete/' + lead.id, variant: "contained", label: "lead", id: lead.id }))),
                    (lead === null || lead === void 0 ? void 0 : lead.status) !== "applied" &&
                        hasAccessTo(auth, lead) &&
                        hasPermission(auth, "edit leads") && (React.createElement(Box, { m: 1 },
                        React.createElement(GenericSetDate, { data: lead, title: "Follow Up Date", buttonName: "Follow Up Date", label: "Follow Up Date", url: "/lead/set-follow-up-date", id: lead.id, name: "follow_up_date" }))),
                    (lead === null || lead === void 0 ? void 0 : lead.status) !== "applied" &&
                        hasAccessTo(auth, lead) &&
                        hasPermission(auth, "edit leads") && (React.createElement(Box, { m: 1 },
                        React.createElement(LeadSetDisposition, { data: lead, options: dispositions.filter(function (el) {
                                return leadDispositionGroupIds.includes(Number(el.disposition_group_id));
                            }), title: "Disposition", buttonName: "Disposition", label: "Dipositions", url: "/lead/set-disposition", id: lead.id, name: "disposition_id" }))))))));
};
export default MemberLeadDetail;
