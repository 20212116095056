import React from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, } from "@mui/material/";
import YearPicker from "../components/YearPicker";
var borders = {
    border: "1px solid #707070",
};
var header = {
    backgroundColor: "#F7F7F7",
    borderBottom: "1px solid #707070",
    borderTop: "1px solid #707070",
};
var headerLeft = {
    backgroundColor: "#F7F7F7",
    borderBottom: "1px solid #707070",
    borderTop: "1px solid #707070",
    borderLeft: "1px solid #707070",
};
var headerRight = {
    backgroundColor: "#F7F7F7",
    borderBottom: "1px solid #707070",
    borderTop: "1px solid #707070",
    borderRight: "1px solid #707070",
};
var columns = [
    { id: "agent", label: "agent" },
    {
        id: "yearly_leads_count",
        label: "Yearly Leads",
    },
    {
        id: "yearly_policies_count",
        label: "Yearly Policies",
    },
    {
        id: "yearly_conversion_rate",
        label: "Yearly Conversion",
    },
];
function ConversionTable(_a) {
    var policies_by_conversion = _a.policies_by_conversion, year = _a.year, setYear = _a.setYear;
    var calculate_conversion = function (filter_key, obj) {
        var leads = 0;
        var policies = 0;
        for (var _i = 0, _a = Object.entries(obj); _i < _a.length; _i++) {
            var _b = _a[_i], key = _b[0], value = _b[1];
            key.includes(filter_key) && key.includes("leads")
                ? (leads = value)
                : key.includes(filter_key) && key.includes("policies")
                    ? (policies = value)
                    : null;
        }
        var percentage = (100 * policies) / leads || "N/A";
        percentage != "N/A"
            ? (percentage = percentage.toFixed(2).replace(/[.,]00$/, "") + "%")
            : null;
        return percentage;
    };
    return (React.createElement(Paper, null, policies_by_conversion && (React.createElement(TableContainer, null,
        React.createElement(Table, null,
            React.createElement(TableHead, null,
                React.createElement(TableRow, null,
                    React.createElement(TableCell, { align: "left", style: headerLeft, colSpan: 1 }),
                    React.createElement(TableCell, { align: "center", style: headerRight, colSpan: 3 },
                        React.createElement(YearPicker, { year: year, setYear: setYear }))),
                React.createElement(TableRow, null,
                    React.createElement(TableCell, { align: "left", style: headerLeft, colSpan: 1 }, "Agents"),
                    React.createElement(TableCell, { align: "center", style: header, colSpan: 1 }, "Total Leads"),
                    React.createElement(TableCell, { align: "center", style: header, colSpan: 1 }, "Policies Written"),
                    React.createElement(TableCell, { align: "center", style: headerRight, colSpan: 1 }, "Conversion Rate"))),
            React.createElement(TableBody, null, policies_by_conversion &&
                policies_by_conversion.map(function (row) {
                    return (React.createElement(TableRow, { role: "checkbox", tabIndex: -1, key: row.agent + "_row" }, columns.map(function (column) {
                        var value = row[column.id];
                        return (React.createElement(TableCell, { key: row.agent +
                                column.id, style: borders, align: !column.id.includes("agent")
                                ? "center"
                                : "left" }, column.id.includes("conversion")
                            ? calculate_conversion("yearly", row)
                            : value));
                    })));
                })))))));
}
export default ConversionTable;
