import { router } from "@inertiajs/react";
import Grid from "@mui/material/Unstable_Grid2/";
import React, { useEffect, useState } from "react";
import DatePicker from "../components/DatePicker";
import ExportButton from "../components/ExportButton";
import OfficeTable from "./OfficeTable";
function Office(_a) {
    var start_date = _a.start_date, end_date = _a.end_date, policies_by_office = _a.policies_by_office, formData = _a.formData, department = _a.department, csrf = _a.csrf;
    var today = new Date();
    var _b = useState(new Date(today.getFullYear(), today.getMonth(), 1)), startDate = _b[0], setStartDate = _b[1];
    var _c = useState(today), endDate = _c[0], setEndDate = _c[1];
    var _d = useState(false), ready = _d[0], setReady = _d[1];
    var handleSubmit = function () {
        router.post("/production-boards/d/".concat(department, "/b/office"), {
            start_date: startDate,
            end_date: endDate,
        });
    };
    useEffect(function () {
        handleSubmit();
        setReady(true);
    }, [startDate, endDate, ready]);
    return (React.createElement(Grid, { container: true, xs: 12, spacing: 3 },
        React.createElement(Grid, { xs: 8 }),
        React.createElement(Grid, { xs: 1 },
            React.createElement(ExportButton, { formData: formData || {}, csrf: csrf || "" })),
        React.createElement(Grid, { xs: 9 }, policies_by_office && (React.createElement(OfficeTable, { policies_by_office: policies_by_office, department: department }))),
        React.createElement(Grid, { xs: 3, spacing: 3 }, start_date && end_date && (React.createElement(DatePicker, { startDate: startDate, endDate: endDate, setStartDate: setStartDate, setEndDate: setEndDate, handleSubmit: handleSubmit })))));
}
export default Office;
