import { Link, usePage } from "@inertiajs/react";
import { Box, Button, Paper, TableContainer } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React from "react";
import route from "ziggy-js";
import PageTitle from "../../../components/PageTitle";
import { hasPermission } from "../../../utils/utils";
var BrokerLicenseTable = function (_a) {
    var _b, _c;
    var data = _a.data;
    var auth = usePage().props.auth;
    if (data.length < 1) {
        return null;
    }
    var rows = [];
    var count = 1;
    for (var _i = 0, data_1 = data; _i < data_1.length; _i++) {
        var item = data_1[_i];
        var values = {
            id: count,
            license: item.license_number,
            type: item.license_type,
            status: item.status,
            state: item.state,
            license_id: item.id,
            user_id: (_c = (_b = item.abs_broker) === null || _b === void 0 ? void 0 : _b.user) === null || _c === void 0 ? void 0 : _c.id,
        };
        rows.push(values);
        count += 1;
    }
    var columns = [
        {
            field: "id",
            headerName: "#",
            editable: false,
            flex: 0.5,
        },
        {
            field: "license",
            headerName: "License Number",
            editable: false,
            flex: 0.5,
        },
        {
            field: "type",
            headerName: "Type",
            editable: false,
            flex: 1,
        },
        {
            field: "status",
            headerName: "Status",
            editable: false,
            flex: 1,
        },
        {
            field: "state",
            headerName: "State",
            editable: false,
            flex: 1,
            renderCell: function (rowValues) { return rowValues.row.state.name; },
        },
        {
            field: "license_id",
            headerName: "Edit",
            editable: false,
            flex: 1,
            renderCell: function (rowValues) {
                var _a, _b;
                return hasPermission(auth, "edit broker-license") && (React.createElement(Button, { component: Link, type: "button", variant: "outlined", fullWidth: true, color: "secondary", href: route("abs_edit_license", [
                        (_a = rowValues === null || rowValues === void 0 ? void 0 : rowValues.row) === null || _a === void 0 ? void 0 : _a.user_id,
                        (_b = rowValues === null || rowValues === void 0 ? void 0 : rowValues.row) === null || _b === void 0 ? void 0 : _b.license_id,
                    ]).toString() }, "Edit"));
            },
        },
    ];
    return (React.createElement(Box, { mt: 2 },
        React.createElement(Box, { mb: 2 },
            React.createElement(PageTitle, { title: "Licenses" })),
        React.createElement(TableContainer, { component: Paper, elevation: 3 },
            React.createElement(DataGrid, { rows: rows, columns: columns, disableColumnSelector: true, disableSelectionOnClick: true, pageSize: 20, autoHeight: true, rowsPerPageOptions: [20] }))));
};
export default BrokerLicenseTable;
