import React, { useState } from "react";
import Layout from "../Layout";
import Grid from "@mui/material/Unstable_Grid2";
import { Button, Typography } from "@mui/material";
import GenericIcon from "../../components/GenericIcon";
import MedicalServicesIcon from "@mui/icons-material/MedicalServices";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import { hasPermission } from "../../utils/utils";
import route from "ziggy-js";
import { usePage } from "@inertiajs/react";
var departments = [
    {
        id: "Medicare",
        name: "Medicare",
        icon: React.createElement(MedicalServicesIcon, { sx: { fontSize: 80, color: "black" } }),
        access: "run medicare production",
    },
    {
        id: "IMM",
        name: "IMM",
        icon: React.createElement(VolunteerActivismIcon, { sx: { fontSize: 80, color: "black" } }),
        access: "run imm production",
    },
    {
        id: "Rosemark",
        name: "Rosemark",
        icon: React.createElement(RequestQuoteIcon, { sx: { fontSize: 80, color: "black" } }),
        access: "run rosemark production",
    },
];
var DepartmentLoader = function (_a) {
    var auth = usePage().props.auth;
    var _b = useState(true), menuOpen = _b[0], toggleMenuOpen = _b[1];
    var _c = useState("Medicare"), department = _c[0], setDepartment = _c[1];
    return (React.createElement(Layout, { title: "Production Boards", menuOpen: menuOpen, toggleMenuOpen: toggleMenuOpen },
        React.createElement(Grid, { container: true, xs: 12 },
            React.createElement("form", { method: "get", action: route("".concat(department, "-production-board"), {
                    department: department,
                    board: "production-boards",
                }) },
                React.createElement(Grid, { container: true, xs: 12 },
                    React.createElement(Grid, { xs: 9 },
                        React.createElement(Typography, { variant: "h5", component: "h2" }, "Production Boards")),
                    React.createElement(Grid, { spacing: 0, xs: 6, container: true }, departments.map(function (department) {
                        if (!hasPermission(auth, department.access)) {
                            return null;
                        }
                        return (React.createElement(Button, { type: "submit", key: department.id },
                            React.createElement(GenericIcon, { id: department.id, title: department.name, icon: department.icon, handleClick: function () {
                                    setDepartment(department.id);
                                } })));
                    })))))));
};
export default DepartmentLoader;
