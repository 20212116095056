import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Container, Typography, Button, MenuItem } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/";
import Layout from "../Layout";
import { isEmpty } from "lodash";
import DisplayErrors from "../../components/DisplayErrors";
import GenericControllerTextInput from "../../components/GenericControllerTextInput";
import GenericControllerDropdownInput from "../../components/GenericControllerDropdownInput";
import { router, Link } from "@inertiajs/react";
import route from "ziggy-js";
var AbsLicenseForm = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h;
    var response_data = _a.response_data, serverErrors = _a.errors;
    var _j = useState(true), menuOpen = _j[0], toggleMenuOpen = _j[1];
    var _k = useForm(), handleSubmit = _k.handleSubmit, errors = _k.formState.errors, control = _k.control;
    var license = response_data.license, states = response_data.states, broker = response_data.broker;
    var _l = useState((_b = license === null || license === void 0 ? void 0 : license.state_id) !== null && _b !== void 0 ? _b : ""), licenseState = _l[0], setLicenseState = _l[1];
    var _m = useState((_c = license === null || license === void 0 ? void 0 : license.status) !== null && _c !== void 0 ? _c : ""), licenseStatus = _m[0], setLicenseStatus = _m[1];
    var _o = useState((_d = license === null || license === void 0 ? void 0 : license.license_type) !== null && _d !== void 0 ? _d : ""), licenseType = _o[0], setLicenseType = _o[1];
    var statusMenuItems = [
        {
            id: "active",
            name: "Active",
        },
        {
            id: "pending",
            name: "Pending",
        },
        {
            id: "inactive",
            name: "Inactive",
        },
    ];
    var typeMenuItems = [
        { id: "Life", name: "Life" },
        { id: "Accident and Health", name: "Accident and Health" },
        {
            id: "Life, Accident, and Health",
            name: "Life, Accident, and Health",
        },
        { id: "Life Broker", name: "Life Broker" },
        { id: "Other", name: "Other" },
    ];
    var onSubmit = function (data) {
        var _a;
        data.abs_broker_id = broker === null || broker === void 0 ? void 0 : broker.id;
        data.user_id = (_a = broker === null || broker === void 0 ? void 0 : broker.user) === null || _a === void 0 ? void 0 : _a.id;
        if (license === null || license === void 0 ? void 0 : license.id) {
            data.license_id = license === null || license === void 0 ? void 0 : license.id;
            router.post("/abs-broker/edit-license/".concat(license === null || license === void 0 ? void 0 : license.id), data);
        }
        else {
            router.post("/abs-broker/create-license", data);
        }
    };
    return (React.createElement(Layout, { title: "ABS Broker", menuOpen: menuOpen, toggleMenuOpen: toggleMenuOpen },
        React.createElement(Container, { maxWidth: false },
            React.createElement("form", { onSubmit: handleSubmit(onSubmit), style: { backgroundColor: "#f8fafc" } },
                React.createElement(Grid, { container: true, spacing: 2 },
                    !isEmpty(serverErrors) && (React.createElement(Grid, { xs: 12 },
                        React.createElement(DisplayErrors, { errors: serverErrors }))),
                    React.createElement(Grid, { xs: 12 },
                        React.createElement(Typography, { variant: "h4", component: "h3", align: "center" }, (license === null || license === void 0 ? void 0 : license.id)
                            ? "Edit ABS License"
                            : "New ABS License")),
                    React.createElement(Grid, { xs: 12, md: 6 },
                        React.createElement(GenericControllerDropdownInput, { label: "State", name: "state_id", defaultValue: licenseState, id: "state_select", handleChange: function (e) {
                                return setLicenseState(e.target.value);
                            }, rules: {
                                required: true,
                            }, control: control, error: "state_id" in errors, options: !!(states === null || states === void 0 ? void 0 : states.length) &&
                                states.map(function (state) { return (React.createElement(MenuItem, { key: state.id, value: state.id }, state.name)); }) })),
                    React.createElement(Grid, { xs: 12, md: 6 },
                        React.createElement(GenericControllerTextInput, { control: control, name: "license_number", label: "License Number", defaultValue: (_e = license === null || license === void 0 ? void 0 : license.license_number) !== null && _e !== void 0 ? _e : "", error: "license_number" in errors })),
                    React.createElement(Grid, { xs: 12, md: 6 },
                        React.createElement(GenericControllerDropdownInput, { label: "License Type", name: "license_type", defaultValue: licenseType, id: "type", handleChange: function (e) {
                                return setLicenseType(e.target.value);
                            }, control: control, rules: {
                                required: true,
                            }, error: "license_type" in errors, options: !!(typeMenuItems === null || typeMenuItems === void 0 ? void 0 : typeMenuItems.length) &&
                                typeMenuItems.map(function (type) { return (React.createElement(MenuItem, { key: type.id, value: type.id }, type.name)); }) })),
                    React.createElement(Grid, { xs: 12, md: 6 },
                        React.createElement(GenericControllerDropdownInput, { label: "Status", name: "status", defaultValue: licenseStatus, id: "status", handleChange: function (e) {
                                return setLicenseStatus(e.target.value);
                            }, control: control, rules: {
                                required: true,
                            }, error: "status" in errors, options: !!(statusMenuItems === null || statusMenuItems === void 0 ? void 0 : statusMenuItems.length) &&
                                statusMenuItems.map(function (status) { return (React.createElement(MenuItem, { key: status.id, value: status.id }, status.name)); }) })),
                    React.createElement(Grid, { xs: 12, md: 6 },
                        React.createElement(GenericControllerTextInput, { control: control, type: "date", label: "Effective Date", name: "effective_date", defaultValue: (_f = license === null || license === void 0 ? void 0 : license.effective_date) !== null && _f !== void 0 ? _f : "", error: "effective_date" in errors, shrinkLabel: true })),
                    React.createElement(Grid, { xs: 12, md: 6 },
                        React.createElement(GenericControllerTextInput, { control: control, type: "date", label: "Expiration Date", name: "expiration_date", defaultValue: (_g = license === null || license === void 0 ? void 0 : license.expiration_date) !== null && _g !== void 0 ? _g : "", error: "expiration_date" in errors, shrinkLabel: true })),
                    React.createElement(Grid, { xs: 12, container: true, alignItems: "center", justifyContent: "center", spacing: 2 },
                        React.createElement(Grid, { xs: 12, md: 6, lg: 3 },
                            React.createElement(Button, { component: Link, type: "button", variant: "outlined", fullWidth: true, color: "secondary", href: route("broker_licenses", [
                                    (_h = broker === null || broker === void 0 ? void 0 : broker.user) === null || _h === void 0 ? void 0 : _h.id,
                                ]).toString() }, "Cancel")),
                        React.createElement(Grid, { xs: 12, md: 6, lg: 3 },
                            React.createElement(Button, { type: "button", variant: "contained", color: "primary", onClick: handleSubmit(onSubmit), fullWidth: true }, (license === null || license === void 0 ? void 0 : license.id) != null
                                ? "Update License"
                                : "Add License"))))))));
};
export default AbsLicenseForm;
