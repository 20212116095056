import React from "react";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Unstable_Grid2/";
var DatePicker = function (_a) {
    var startDate = _a.startDate, endDate = _a.endDate, setStartDate = _a.setStartDate, setEndDate = _a.setEndDate, handleSubmit = _a.handleSubmit;
    return (React.createElement("form", { noValidate: true },
        React.createElement(Grid, { container: true, xs: 12, spacing: 1 },
            React.createElement(Grid, { xs: 6 },
                React.createElement(TextField, { id: "start-date", label: "start date", type: "date", defaultValue: startDate.toISOString().split("T")[0], onChange: handleSubmit
                        ? function (e) {
                            handleSubmit();
                            setStartDate(new Date(e.target.value));
                        }
                        : function (e) {
                            setStartDate(new Date(e.target.value));
                        }, InputLabelProps: {
                        shrink: true,
                    } })),
            React.createElement(Grid, { xs: 6 },
                React.createElement(TextField, { id: "end-date", label: "end date", type: "date", defaultValue: endDate.toISOString().split("T")[0], onChange: function (e) {
                        setEndDate(new Date(e.target.value));
                    }, InputLabelProps: {
                        shrink: true,
                    } })))));
};
export default DatePicker;
