import React from "react";
import { Menu, MenuItem, Button, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import route from "ziggy-js";
import { hasPermission } from "../../utils/utils";
import { usePage } from "@inertiajs/react";
var boardsOptions = [
    "Production Boards",
    "Carriers",
    "Conversion",
    "Policy",
    "Office",
    "Business",
    "Monthly Totals",
];
var departmentOptions = [
    { name: "Medicare", access: "run medicare production" },
    { name: "IMM", access: "run imm production" },
    { name: "Rosemark", access: "run rosemark production" },
];
var ProductionBoardsNav = function (_a) {
    var board = _a.board, department = _a.department;
    var auth = usePage().props.auth;
    var _b = React.useState(null), boardAnchorEl = _b[0], setBoardAnchorEl = _b[1];
    var _c = React.useState(null), departmentAnchorEl = _c[0], setDepartmentAnchorEl = _c[1];
    var handleClick = function (event, handler) {
        handler(event.currentTarget);
    };
    var handleClose = function (handler) {
        handler(null);
    };
    var showDepartments = function () {
        return departmentOptions.filter(function (option) {
            return hasPermission(auth, option.access);
        }).length > 1
            ? true
            : false;
    };
    return (React.createElement(React.Fragment, null, department && (React.createElement(Grid, { spacing: 3, xs: 12, container: true },
        React.createElement(Grid, { xs: 6 },
            React.createElement(Typography, { variant: "h4", component: "h4" }, department +
                " - " +
                boardsOptions[boardsOptions.findIndex(function (option) {
                    return option
                        .toLowerCase()
                        .replace(" ", "-") === board;
                })])),
        React.createElement(Grid, { xs: 3, container: true, spacing: 1, justifyContent: "end" }, showDepartments() && (React.createElement(Button, { "aria-controls": "departments-menu", "aria-haspopup": "true", variant: "outlined", onClick: function (e) {
                return handleClick(e, setDepartmentAnchorEl);
            } },
            department,
            React.createElement(ArrowDropDownIcon, null)))),
        React.createElement(Menu, { id: "department-menu", anchorEl: departmentAnchorEl, keepMounted: true, open: Boolean(departmentAnchorEl), onClose: function () { return handleClose(setDepartmentAnchorEl); } }, departmentOptions.map(function (option) {
            if (!hasPermission(auth, option.access)) {
                return null;
            }
            return (React.createElement("form", { method: "get", action: route("".concat(option.name, "-production-board"), {
                    department: option.name,
                    board: board,
                }), key: option.name + "department-menu-form" },
                React.createElement(Button, { variant: "text", fullWidth: true, type: "submit" },
                    React.createElement(MenuItem, { onClick: function () {
                            return handleClose(setDepartmentAnchorEl);
                        }, key: option.name +
                            "department-menu-item" }, option.name))));
        })),
        React.createElement(Grid, { xs: 3, container: true, spacing: 1 },
            React.createElement(Button, { "aria-controls": "production-board-menu", "aria-haspopup": "true", variant: "outlined", onClick: function (e) { return handleClick(e, setBoardAnchorEl); } },
                boardsOptions[boardsOptions.findIndex(function (option) {
                    return option
                        .toLowerCase()
                        .replace(" ", "-") === board;
                })],
                React.createElement(ArrowDropDownIcon, null))),
        React.createElement(Menu, { id: "production-board-menu", anchorEl: boardAnchorEl, keepMounted: true, open: Boolean(boardAnchorEl), onClose: function () { return handleClose(setBoardAnchorEl); } }, boardsOptions.map(function (option) {
            return (React.createElement("form", { method: "get", action: route("".concat(department, "-production-board"), {
                    department: department,
                    board: option
                        .toLowerCase()
                        .replace(" ", "-"),
                }), key: option.toLowerCase().replace(" ", "-") +
                    "-menu-form" },
                React.createElement(Button, { variant: "text", fullWidth: true, type: "submit" },
                    React.createElement(MenuItem, { onClick: function () {
                            return handleClose(setBoardAnchorEl);
                        }, key: option
                            .toLowerCase()
                            .replace(" ", "-") +
                            "-menu-item" }, option))));
        }))))));
};
export default ProductionBoardsNav;
