import { Box, Grid, Paper, Snackbar, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Layout from "../Layout";
import { groupBy, isEmpty } from "lodash";
import DisplayErrors from "../../components/DisplayErrors";
import MemberLead from "./components/MemberLead";
import LeadSelection from "./components/LeadSelection";
import { Alert } from '@mui/material';
import { filterDeletedLeads, getShowDeleted } from "../../utils/utils";
import { MemberNavWrapper } from "./components/MemberNavWrapper";
import MemberHeader from "./components/MemberHeader";
var MemberLeads = function (_a) {
    var _b, _c, _d, _e, _f;
    var response_data = _a.response_data, flash = _a.flash, serverErrors = _a.errors;
    var _g = useState(true), menuOpen = _g[0], toggleMenuOpen = _g[1];
    var _h = useState(false), openMessage = _h[0], setOpenMessage = _h[1];
    var _j = useState(function () { return getShowDeleted(); }), showDeleted = _j[0], setShowDeleted = _j[1];
    var member = response_data.member, all_documents = response_data.all_documents, lead_types = response_data.lead_types, agents = response_data.agents, states = response_data.states, dispositions = response_data.dispositions;
    var leads = groupBy((_b = member.leads) === null || _b === void 0 ? void 0 : _b.filter(function (el) { return filterDeletedLeads(el, showDeleted); }), "lead_type_id");
    var leadIds = Object.keys(leads);
    var _k = useState((_c = leadIds === null || leadIds === void 0 ? void 0 : leadIds[0]) !== null && _c !== void 0 ? _c : null), leadId = _k[0], setLeadId = _k[1];
    useEffect(function () {
        if (flash.message) {
            setOpenMessage(true);
        }
    }, [flash]);
    useEffect(function () {
        localStorage.setItem("showDeleted", JSON.stringify(showDeleted));
    }, [showDeleted]);
    return (React.createElement(Layout, { title: "Member Info", menuOpen: menuOpen, toggleMenuOpen: toggleMenuOpen },
        React.createElement(Grid, { container: true },
            React.createElement(Grid, { container: true, item: true, xs: 12, justifyContent: "center", alignContent: "center", spacing: 4 },
                React.createElement(MemberNavWrapper, { member: member, all_documents: all_documents, showDeleted: showDeleted }))),
        !isEmpty(serverErrors) && (React.createElement(Grid, { container: true, spacing: 2 },
            React.createElement(Grid, { item: true, xs: 12 },
                React.createElement(Box, { my: 2 },
                    React.createElement(DisplayErrors, { errors: serverErrors }))))),
        React.createElement(Grid, { container: true },
            React.createElement(Grid, { item: true, xs: 3 },
                React.createElement(MemberHeader, { member: member })),
            React.createElement(Grid, { item: true, xs: 9 }),
            React.createElement(Grid, { item: true, xs: 12 }, openMessage && (React.createElement(Box, { mb: 1 },
                React.createElement(Snackbar, { open: openMessage, anchorOrigin: {
                        vertical: "top",
                        horizontal: "center",
                    }, autoHideDuration: 3000, onClose: function () { return setOpenMessage(false); } },
                    React.createElement(Alert, { onClose: function () { return setOpenMessage(false); }, severity: "success" }, flash === null || flash === void 0 ? void 0 : flash.message))))),
            React.createElement(Grid, { item: true, xs: 12 },
                React.createElement(Box, { mb: 1 },
                    React.createElement(LeadSelection, { member: member, leadTypes: lead_types, leadId: leadId, leadIds: leadIds, leads: leads, setLeadId: setLeadId, setShowDeleted: setShowDeleted, showDeleted: showDeleted }))),
            React.createElement(Grid, { item: true, xs: 12 },
                React.createElement(Box, { mb: 2 },
                    React.createElement(Typography, { variant: "subtitle2" }, "*Disclaimer: In order to promote a lead to an app, the disposition \"Application Submitted to Carrier\" must be used.")), (_f = (_e = (_d = member === null || member === void 0 ? void 0 : member.leads) === null || _d === void 0 ? void 0 : _d.filter(function (el) { return el.lead_type_id == leadId; })) === null || _e === void 0 ? void 0 : _e.filter(function (el) {
                return filterDeletedLeads(el, showDeleted);
            })) === null || _f === void 0 ? void 0 :
                _f.map(function (lead) { return (React.createElement(Box, { mb: 1, key: lead.id, id: "lead_".concat(lead.id) },
                    React.createElement(Paper, null,
                        React.createElement(MemberLead, { member: member, lead: lead, leadTypes: lead_types, agents: agents, states: states, dispositions: dispositions })))); })))));
};
export default MemberLeads;
