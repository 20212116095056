import React from "react";
import isEmpty from "lodash/isEmpty";
import GenericControllerTextInput from "../../../components/GenericControllerTextInput";
import { Paper } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/";
import StatusContact from "../../../components/StatusContact";
var LeadStatusContact = function (_a) {
    var control = _a.control, watch = _a.watch, errors = _a.errors, lead = _a.lead, referrals = _a.referrals, dispositionGroup = _a.dispositionGroup, dispositionId = _a.dispositionId, setDispositionId = _a.setDispositionId, resetField = _a.resetField, originalDispositionId = _a.originalDispositionId;
    // show notes only when new lead is added and if the disposition was changed to Urgent Action(10) or In Progress(4)
    function noteRequired() {
        return (isEmpty(lead) ||
            (parseInt(originalDispositionId) !== parseInt(dispositionId) &&
                (parseInt(dispositionId) === 10 ||
                    parseInt(dispositionId) === 4)));
    }
    return (React.createElement(Grid, { xs: 12, container: true, component: Paper, spacing: 2 },
        React.createElement(StatusContact, { control: control, watch: watch, errors: errors, data: lead, referrals: referrals, dispositionId: dispositionId, setDispositionId: setDispositionId, dispositionGroup: dispositionGroup, resetField: resetField }),
        React.createElement(Grid, { xs: 12, container: true, spacing: 2 },
            React.createElement(Grid, { xs: 12 },
                React.createElement(Grid, { xs: 12 },
                    React.createElement(GenericControllerTextInput, { control: control, className: "login-input", variant: "outlined", label: "Lead Note", defaultValue: "", multiline: true, type: "text", fullWidth: true, rows: 5, rules: noteRequired()
                            ? { required: true }
                            : { required: false }, name: "lead_note_text", error: "lead_note_text" in errors }))))));
};
export default LeadStatusContact;
