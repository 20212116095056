import React from "react";
import TextField from "@mui/material/TextField";
import { Divider, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/";
import CreditCardForm from "../../../components/CreditCardForm";
import GenericControllerSelect from "../../../components/GenericControllerSelect";
import GenericAutoComplete from "../../../components/GenericAutoComplete";
import GenericNumberInput from "../../../components/GenericNumberInput";
import GenericControllerCheckboxInput from "../../../components/GenericControllerCheckboxInput";
import GenericControllerTextInput from "../../../components/GenericControllerTextInput";
import { Controller } from "react-hook-form";
export default function AddLicenseForm(props) {
    var _a, _b;
    var referrals = props.referrals, member = props.member, products = props.products, errors = props.errors, watch = props.watch, control = props.control;
    var watchCardNumber = watch("payment[card_number]", "");
    var watchPaymentType = watch("payment[type]", "");
    var watchContribution = watch("payment[contribution]", 0);
    var watchProductId = watch("product_id", "");
    var watchReferral = watch("referral", {});
    var renewalOptions = [
        {
            id: "0",
            name: "No",
        },
        {
            id: "1",
            name: "Yes",
        },
    ];
    var paymentOptions = [
        {
            id: "check",
            name: "Check",
        },
        {
            id: "cash",
            name: "Cash",
        },
        {
            id: "money-order",
            name: "Money Order",
        },
        {
            id: "credit",
            name: "Credit Card",
        },
    ];
    return (React.createElement(Grid, { container: true, xs: 12, spacing: 2 },
        React.createElement(Grid, { xs: 12, container: true, spacing: 2 },
            React.createElement(Grid, { xs: 12 },
                React.createElement(Typography, { variant: "h5", component: "h3", className: "section-title" }, "Product"))),
        React.createElement(Grid, { xs: 12, container: true, spacing: 2 },
            React.createElement(Grid, { xs: 12, md: 6 },
                React.createElement(GenericControllerSelect, { name: "product_id", control: control, label: "Product", options: products, rules: {
                        required: member.id ? false : true,
                    }, error: "product_id" in errors, defaultValue: watchProductId })),
            [2, 3, 4, 5, 6, 7].includes(watchProductId) && (React.createElement(Grid, { xs: 12, md: 6 },
                React.createElement(GenericControllerSelect, { name: "payment[type]", control: control, label: "Payment Type", options: paymentOptions, rules: {
                        required: [2, 3, 4, 5, 6, 7].includes(watchProductId)
                            ? true
                            : false,
                        validate: function (value) {
                            return [2, 3, 4, 5, 6, 7].includes(watchProductId)
                                ? value.length > 0
                                : true;
                        },
                    }, error: errors.payment && "type" in errors.payment, defaultValue: watchPaymentType }))),
            member.id && [1, 2, 3, 4, 5, 6].includes(watchProductId) && (React.createElement(Grid, { xs: 12, md: 6 },
                React.createElement(GenericControllerSelect, { name: "renewal", defaultValue: member.renewal || "", control: control, rules: {
                        required: true,
                    }, label: "Renewal", options: renewalOptions, error: "renewal" in errors }))),
            watchProductId && (React.createElement(Grid, { xs: 12, md: 6 },
                React.createElement(GenericNumberInput, { label: "Contribution Amount", rules: {
                        required: watchProductId == 7 ? true : false,
                        validate: function (value) {
                            return watchProductId == 7 ? value > 0 : true;
                        },
                    }, name: "payment[contribution]", defaultValue: watchContribution, error: errors.contribution &&
                        "contribution" in errors.payment, control: control })))),
        React.createElement(Grid, { container: true, xs: 12, spacing: 2 },
            React.createElement(Divider, { light: true })),
        React.createElement(Grid, { xs: 12, container: true, spacing: 2 },
            ["cash", "check", "money-order"].includes(watchPaymentType) && (React.createElement(Grid, { xs: 12, md: 6 },
                React.createElement(Controller, { control: control, name: "payment[usaepay_id]", defaultValue: member.usaepay_id, rules: {
                        required: false,
                    }, render: function (_a) {
                        var field = _a.field;
                        return (React.createElement(TextField, { variant: "outlined", label: "Transaction Id", type: "text", name: "payment[usaepay_id]", error: errors.payment &&
                                "usaepay_id" in errors.payment, fullWidth: true }));
                    } }))),
            [1, 2, 3, 4, 5, 6, 7, 21].includes(watchProductId) && (React.createElement(Grid, { xs: 12, md: 6 },
                React.createElement(GenericAutoComplete, { limitTags: 1, name: "referral", control: control, label: "Referral", options: referrals, rules: {
                        required: true,
                    }, error: "referral" in errors, value: (member === null || member === void 0 ? void 0 : member.referral_id) || null, multiple: false }),
                React.createElement(Controller, { control: control, name: "referral_id", defaultValue: (_a = watchReferral === null || watchReferral === void 0 ? void 0 : watchReferral.id) !== null && _a !== void 0 ? _a : "", render: function (_a) {
                        var field = _a.field;
                        return (React.createElement(TextField, { type: "hidden", name: "referral_id" }));
                    } }))),
            (watchReferral === null || watchReferral === void 0 ? void 0 : watchReferral.id) == 18 && (React.createElement(Grid, { xs: 12, sm: 6, xl: 4 },
                React.createElement(GenericControllerTextInput, { name: "referral_other", control: control, label: "Referral Other", defaultValue: (_b = member === null || member === void 0 ? void 0 : member.referral_other) !== null && _b !== void 0 ? _b : "", rules: {
                        required: true,
                    } })))),
        React.createElement(Grid, { xs: 12, container: true, spacing: 2 },
            React.createElement(Divider, { light: true })),
        watchPaymentType === "credit" && (React.createElement(Grid, { xs: 12, container: true, spacing: 2 },
            React.createElement(CreditCardForm, { member: member, control: control, errors: errors, cardNumber: watchCardNumber, showContribution: false }))),
        watchPaymentType === "credit" && (React.createElement(Grid, { xs: 12, md: 6 },
            React.createElement(GenericControllerCheckboxInput, { defaultChecked: (member === null || member === void 0 ? void 0 : member.recurring) ? true : false, name: "payment[recurring]", control: control, label: "Auto Renewal" })))));
}
