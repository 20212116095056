import { Link, usePage } from "@inertiajs/react";
import { Box, Button, Grid } from "@mui/material";
import React, { useState } from "react";
import LeadTable from "../../components/LeadTable";
import { hasPermission } from "../../utils/utils";
import Layout from "../Layout";
import BrokerNav from "./components/BrokerNav";
function LinkActive(props) {
    return (React.createElement(Link, { href: props.url, as: "span", key: props.label },
        React.createElement(Button, { variant: props.active ? "contained" : "outlined", color: props.active ? "secondary" : "primary" }, props.label
            .replace("&laquo; Previous", "Prev")
            .replace("Next &raquo;", "Next"))));
}
function LinkInactive(props) {
    if (props.label == "&laquo; Previous") {
        return null;
    }
    if (props.label == "Next &raquo;") {
        return null;
    }
    return (React.createElement(Button, { variant: "outlined", color: "primary", disabled: true }, props.label
        .replace("&laquo; Previous", "Prev")
        .replace("Next &raquo;", "Next")));
}
function PageLink(props) {
    var hasUrl = props.url;
    if (hasUrl) {
        return (React.createElement(LinkActive, { label: props.label, url: props.url, active: props.active }));
    }
    return React.createElement(LinkInactive, { label: props.label });
}
var AbsLeads = function (_a) {
    var response_data = _a.response_data;
    var _b = useState(true), menuOpen = _b[0], toggleMenuOpen = _b[1];
    var auth = usePage().props.auth;
    var broker = response_data.broker, carrier_count = response_data.carrier_count, lead_count = response_data.lead_count, document_count = response_data.document_count, license_count = response_data.license_count, leads = response_data.leads, lead_types = response_data.lead_types, agents = response_data.agents, states = response_data.states, dispositions = response_data.dispositions;
    return (React.createElement(Layout, { title: "Member Info", menuOpen: menuOpen, toggleMenuOpen: toggleMenuOpen },
        React.createElement(Grid, { container: true, spacing: 2 },
            hasPermission(auth, "access abs") && (React.createElement(Grid, { container: true, item: true, xs: 12, alignItems: "center", justifyContent: "center" },
                React.createElement(BrokerNav, { broker: broker, lead_count: lead_count, document_count: document_count, carrier_count: carrier_count, license_count: license_count }))),
            React.createElement(Grid, { item: true, xs: 12 },
                React.createElement(Grid, { container: true, direction: "column", alignItems: "center", justifyContent: "center" },
                    React.createElement(Grid, { item: true, xs: 12 },
                        React.createElement(Box, { m: 3 }, leads["links"].map(function (link, index) {
                            return (React.createElement(PageLink, { label: link.label, url: link.url, key: "search_link_" + index, active: link.active }));
                        }))))),
            React.createElement(Grid, { item: true, xs: 12 },
                React.createElement(LeadTable, { data: leads.data, title: "Leads", lead_types: lead_types, agents: agents, states: states, dispositions: dispositions })))));
};
export default AbsLeads;
