import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Layout from "../Layout";
import { Link } from "@inertiajs/react";
import { Button, TableContainer, Paper, Grid, Box } from "@mui/material";
import route from "ziggy-js";
import { helperFunctions } from "../../Helpers";
import { formatMemberStatus } from "../../utils/utils";
var columns = [
    {
        field: "id",
        headerName: "Member ID",
        editable: false,
        hideSortIcons: true,
        disableColumnMenu: true,
        flex: 1,
    },
    {
        field: "full_name",
        headerName: "Name",
        editable: false,
        flex: 1.5,
    },
    {
        field: "email",
        headerName: "Email",
        editable: false,
        flex: 1.5,
    },
    {
        field: "spouse_full_name",
        headerName: "Spouse",
        editable: false,
        flex: 1.5,
        hideSortIcons: true,
        disableColumnMenu: true,
    },
    {
        field: "state.abbreviation",
        headerName: "State",
        editable: false,
        flex: 0.7,
        hideSortIcons: false,
        disableColumnMenu: false,
        headerAlign: "center",
        align: "center",
        renderCell: function (rowValues) { var _a, _b; return "".concat((_b = (_a = rowValues.row.state) === null || _a === void 0 ? void 0 : _a.abbreviation) !== null && _b !== void 0 ? _b : ""); },
    },
    {
        field: "member_since",
        headerName: "Member Since",
        editable: false,
        flex: 0.5,
        hideSortIcons: true,
        disableColumnMenu: true,
        headerAlign: "center",
        align: "center",
        renderCell: function (rowValues) {
            return helperFunctions.formatDate(rowValues.row.member_since);
        },
    },
    {
        field: "member_expiration_date",
        headerName: "Expiration",
        editable: false,
        flex: 0.5,
        hideSortIcons: true,
        disableColumnMenu: true,
        renderCell: function (rowValues) {
            return helperFunctions.formatDate(rowValues.row.member_expiration_date);
        },
    },
    {
        field: "status",
        headerName: "Status",
        editable: false,
        flex: 0.5,
        hideSortIcons: true,
        disableColumnMenu: true,
        renderCell: function (rowValues) {
            var _a, _b, _c;
            return "".concat(formatMemberStatus((_a = rowValues.row) === null || _a === void 0 ? void 0 : _a.status, (_b = rowValues.row) === null || _b === void 0 ? void 0 : _b.merged_to_id, !!((_c = rowValues.row) === null || _c === void 0 ? void 0 : _c.member_leave_reason_id)));
        },
    },
    {
        field: "view",
        headerName: "View",
        sortable: false,
        filterable: false,
        hideSortIcons: true,
        disableColumnMenu: true,
        align: "center",
        headerAlign: "center",
        flex: 1,
        renderCell: function (rowValues) {
            return (React.createElement(Link, { href: route("show_member", { id: rowValues.id }), as: "span" },
                React.createElement(Button, { variant: "outlined", color: "primary", fullWidth: true }, "View")));
        },
    },
];
function LinkActive(props) {
    var zip = props.zip ? "&zip=".concat(props.zip) : "";
    var query = props.query ? "&query=".concat(props.query) : "";
    return (React.createElement(Link, { href: props.url + query + zip, as: "span", key: props.label },
        React.createElement(Button, { variant: props.active ? "contained" : "outlined", color: props.active ? "secondary" : "primary" }, props.label
            .replace("&laquo; Previous", "Prev")
            .replace("Next &raquo;", "Next"))));
}
function LinkInactive(props) {
    if (props.label == "&laquo; Previous") {
        return null;
    }
    if (props.label == "Next &raquo;") {
        return null;
    }
    return (React.createElement(Button, { variant: "outlined", color: "primary", disabled: true }, props.label
        .replace("&laquo; Previous", "Prev")
        .replace("Next &raquo;", "Next")));
}
function PageLink(props) {
    var hasUrl = props.url;
    if (hasUrl) {
        return (React.createElement(LinkActive, { query: props.query, zip: props.zip, label: props.label, url: props.url, active: props.active }));
    }
    return React.createElement(LinkInactive, { label: props.label });
}
var MemberSearchResults = function (_a) {
    var response_data = _a.response_data;
    var pagesize = 15;
    var current_page = response_data["members"]["current_page"];
    var last_page = response_data["members"]["last_page"];
    var query = response_data["query"];
    var zip = response_data["zip"];
    var links = response_data["members"]["links"];
    var members = response_data["members"]["data"];
    var _b = useState(true), menuOpen = _b[0], toggleMenuOpen = _b[1];
    var rows = [];
    members.map(function (member) {
        rows.push(member);
    });
    return (React.createElement(Layout, { title: "Member Search Results", menuOpen: menuOpen, toggleMenuOpen: toggleMenuOpen },
        response_data.query || response_data.zip ? (React.createElement("h3", null,
            "Search for:",
            " ",
            React.createElement("span", { className: "font-weight-bold" },
                (response_data === null || response_data === void 0 ? void 0 : response_data.query)
                    ? "Query: ".concat(response_data.query)
                    : "",
                (response_data === null || response_data === void 0 ? void 0 : response_data.zip) ? " Zip: ".concat(response_data.zip) : ""))) : (React.createElement("h3", null, "Showing all members")),
        React.createElement("span", null,
            "Page ",
            current_page,
            " of ",
            last_page),
        React.createElement(TableContainer, { component: Paper, elevation: 3 },
            React.createElement(DataGrid, { rows: rows, columns: columns, disableColumnSelector: true, disableSelectionOnClick: true, pageSize: pagesize, rowsPerPageOptions: [pagesize], autoHeight: true, hideFooter: true }),
            React.createElement(Grid, { container: true, direction: "column", alignItems: "center", justifyContent: "center" },
                React.createElement(Grid, { item: true, xs: 12 },
                    React.createElement(Box, { m: 3 }, links.map(function (link, index) {
                        return (React.createElement(PageLink, { query: query, zip: zip, label: link.label, url: link.url, key: "search_link_" + index, active: link.active }));
                    })))))));
};
export default MemberSearchResults;
