var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React from "react";
import { Autocomplete, Checkbox, MenuItem, TextField, } from "@mui/material";
import { createFilterOptions } from "@mui/material/Autocomplete";
var CarriersPicker = function (_a) {
    var _b = _a.carriers, carriers = _b === void 0 ? [] : _b, setSelectedCarriers = _a.setSelectedCarriers, _c = _a.selectedCarriers, selectedCarriers = _c === void 0 ? [] : _c;
    var _d = [
        selectedCarriers,
        setSelectedCarriers,
    ], selectedOptions = _d[0], setSelectedOptions = _d[1];
    var allSelected = carriers.length === selectedOptions.length;
    console.log(selectedOptions.length, carriers.length);
    var handleToggleOption = function (selectedOps) {
        return setSelectedOptions(selectedOps);
    };
    var handleClearOptions = function () { return setSelectedOptions([]); };
    var handleSelectAll = function (isSelected) {
        if (isSelected) {
            setSelectedOptions(carriers);
        }
        else {
            handleClearOptions();
        }
    };
    var handleToggleSelectAll = function () {
        handleSelectAll && handleSelectAll(!allSelected);
    };
    var handleChange = function (event, selectedOps, reason) {
        if (reason === "selectOption" || reason === "removeOption") {
            if (selectedOps.find(function (option) { return option.name === "Select All"; })) {
                console.log(selectedOps);
                handleToggleSelectAll();
                var result = carriers.filter(function (el) { return el.name !== "Select All"; });
                console.log(result);
                return setSelectedOptions(result);
            }
            else {
                handleToggleOption && handleToggleOption(selectedOps);
                return setSelectedOptions(selectedOps);
            }
        }
        else if (reason === "clear") {
            handleClearOptions();
        }
    };
    var filter = createFilterOptions();
    return (React.createElement(Autocomplete, { multiple: true, disableCloseOnSelect: true, limitTags: 3, size: "small", id: "carriers-select", options: carriers, isOptionEqualToValue: function (option, value) { return option.id === value.id; }, value: selectedCarriers, filterOptions: function (options, params) {
            var filtered = filter(options, params);
            return __spreadArray([{ id: 0, name: "Select All" }], filtered, true);
        }, onChange: handleChange, getOptionLabel: function (option) { return option.name; }, renderOption: function (props, option, _a) {
            var selected = _a.selected;
            var selectAllProps = option.name === "Select All"
                ? { checked: allSelected }
                : {};
            return (React.createElement(MenuItem, __assign({}, props),
                React.createElement(Checkbox, __assign({ checked: selected }, selectAllProps)),
                option.name));
        }, renderInput: function (params) { return (React.createElement(TextField, __assign({}, params, { placeholder: "Insurance Carriers" }))); } }));
};
export default CarriersPicker;
