var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Checkbox, TextField } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { Autocomplete } from "@mui/material";
import { createFilterOptions } from "@mui/material/useAutocomplete";
import React from "react";
import { Controller } from "react-hook-form";
var useStyles = makeStyles(function (theme) {
    return createStyles({
        textfield: {
            maxHeight: "3.5em",
            "& .Mui-focused": {
                backgroundColor: "#fff",
            },
            "& .MuiButtonBase-root.MuiAutocomplete-clearIndicator": {
                color: "red",
            },
        },
    });
});
var GenericAutoComplete = function (_a) {
    var name = _a.name, label = _a.label, options = _a.options, control = _a.control, value = _a.value, rules = _a.rules, error = _a.error, placeholder = _a.placeholder, limitTags = _a.limitTags, _b = _a.multiple, multiple = _b === void 0 ? true : _b, _c = _a.disableCloseOnSelect, disableCloseOnSelect = _c === void 0 ? false : _c;
    var classes = useStyles();
    var filterOptions = createFilterOptions({
        matchFrom: "any",
        limit: 150,
        trim: true,
    });
    return (React.createElement(Controller, { name: name, rules: rules, defaultValue: value, render: function (_a) {
            var _b = _a.field, onChange = _b.onChange, onBlur = _b.onBlur, value = _b.value;
            var props = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                props[_i - 1] = arguments[_i];
            }
            return (React.createElement(Autocomplete, __assign({}, props, { multiple: multiple, disableCloseOnSelect: disableCloseOnSelect, options: options.map(function (el) { return ({
                    id: el.id,
                    name: el.name,
                }); }), getOptionLabel: function (option) { return option.name; }, limitTags: limitTags, filterOptions: filterOptions, isOptionEqualToValue: function (option, value) {
                    return option.id == value.id;
                }, renderOption: function (props, option, _a) {
                    var selected = _a.selected;
                    return (React.createElement("li", __assign({}, props, { key: option.name + "_" + option.id + "_li" }),
                        React.createElement(Checkbox, { key: option.name +
                                "_" +
                                option.id +
                                "_checkbox", style: { marginRight: 8 }, checked: selected }),
                        option.name));
                }, value: value, renderInput: function (params) { return (React.createElement(TextField, __assign({}, params, { variant: "outlined", label: label, error: error, className: classes.textfield, placeholder: placeholder, fullWidth: true }))); }, onChange: function (_, data) { return onChange(data); }, fullWidth: true })));
        }, control: control }));
};
export default GenericAutoComplete;
