import { MenuItem, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/";
import React, { useState } from "react";
import GenericControllerDropdownInput from "../../../components/GenericControllerDropdownInput";
import GenericNumberInput from "../../../components/GenericNumberInput";
import GenericControllerTextInput from "../../../components/GenericControllerTextInput";
var BrokerInfo = function (_a) {
    var _b, _c, _d;
    var data = _a.data, errors = _a.errors, states = _a.states, control = _a.control;
    var _e = useState((_b = data === null || data === void 0 ? void 0 : data.status) !== null && _b !== void 0 ? _b : "new"), status = _e[0], setStatus = _e[1];
    var _f = useState((_c = data === null || data === void 0 ? void 0 : data.dom_state_id) !== null && _c !== void 0 ? _c : ""), state = _f[0], setState = _f[1];
    return (React.createElement(Grid, { xs: 12, container: true, spacing: 2 },
        React.createElement(Grid, { xs: 12 },
            React.createElement(Typography, { variant: "h5", component: "h3", className: "section-title" }, "Broker / Account Info")),
        React.createElement(Grid, { xs: 12, container: true, spacing: 2 },
            React.createElement(Grid, { xs: 12, md: 6 },
                React.createElement(GenericControllerDropdownInput, { label: "Status", name: "broker[status]", id: "status", defaultValue: status, handleChange: function (e) { return setStatus(e.target.value); }, rules: {
                        required: true,
                    }, control: control, error: errors.error.errors.broker &&
                        "status" in errors.error.errors.broker, options: [
                        { value: "new", name: "New" },
                        { value: "pending", name: "Pending" },
                        { value: "active", name: "Active" },
                        { value: "inactive", name: "Inactive" },
                    ].map(function (option) {
                        return (React.createElement(MenuItem, { key: option.value, value: option.value }, option.name));
                    }) })),
            React.createElement(Grid, { xs: 12, md: 6 },
                React.createElement(GenericNumberInput, { name: "broker[npn]", label: "NPN Number", type: "text", error: errors.error.errors.broker &&
                        "npn" in errors.error.errors.broker, rules: { required: true }, defaultValue: (_d = data === null || data === void 0 ? void 0 : data.npn) !== null && _d !== void 0 ? _d : "", control: control }))),
        React.createElement(Grid, { xs: 12, container: true, spacing: 2 },
            (data === null || data === void 0 ? void 0 : data.id) ? null : (React.createElement(Grid, { xs: 12, md: 6 },
                React.createElement(GenericControllerTextInput, { name: "user[password]", type: "password", label: "Password", error: errors.error.errors.user &&
                        "password" in errors.error.errors.user, rules: {
                        required: true,
                    }, defaultValue: "", control: control }))),
            React.createElement(React.Fragment, null, true && console.log(errors)),
            React.createElement(Grid, { xs: 12, md: 6 },
                React.createElement(GenericControllerDropdownInput, { label: "Domicile State", name: "broker[dom_state_id]", id: "domicile_state", defaultValue: state, handleChange: function (e) { return setState(e.target.value); }, rules: {
                        required: true,
                    }, control: control, error: errors.error.errors.broker &&
                        "dom_state_id" in errors.error.errors.broker, options: !!(states === null || states === void 0 ? void 0 : states.length) &&
                        states.map(function (state) { return (React.createElement(MenuItem, { key: state.id, value: state.id }, state.name)); }) })))));
};
export default BrokerInfo;
