import { Link, usePage } from "@inertiajs/react";
import { Button } from "@mui/material";
import React from "react";
var BrokerNav = function (_a) {
    var broker = _a.broker, lead_count = _a.lead_count, carrier_count = _a.carrier_count, document_count = _a.document_count, license_count = _a.license_count;
    var url = usePage().url;
    return (React.createElement(React.Fragment, null,
        React.createElement(Link, { href: "/abs-broker/show/".concat(broker.user.id) },
            React.createElement(Button, { type: "button", color: "primary", variant: "outlined", style: {
                    backgroundColor: url.includes("/abs-broker/show/")
                        ? "#E21D37"
                        : "#1E5496",
                    color: "#FFF",
                    textDecoration: "none",
                    borderRadius: "0 0px 3px 3px",
                } }, "Broker Detail")),
        React.createElement(Link, { href: "/abs-broker/show-leads/".concat(broker.user.id) },
            React.createElement(Button, { type: "button", color: "primary", variant: "outlined", style: {
                    backgroundColor: url.includes("/abs-broker/show-leads/")
                        ? "#E21D37"
                        : "#1E5496",
                    color: "#FFF",
                    textDecoration: "none",
                    borderRadius: "0 0px 3px 3px",
                } },
                "Leads (",
                lead_count,
                ")")),
        React.createElement(Link, { href: "/abs-broker/show-carriers/".concat(broker.user.id) },
            React.createElement(Button, { type: "button", color: "primary", variant: "outlined", style: {
                    backgroundColor: url.includes("/abs-broker/show-carriers/")
                        ? "#E21D37"
                        : "#1E5496",
                    color: "#FFF",
                    textDecoration: "none",
                    borderRadius: "0 0px 3px 3px",
                } },
                "Carrier (",
                carrier_count,
                ")")),
        React.createElement(Link, { href: "/abs-broker/show-licenses/".concat(broker.user.id) },
            React.createElement(Button, { type: "button", color: "primary", variant: "outlined", style: {
                    backgroundColor: url.includes("/abs-broker/show-licenses/")
                        ? "#E21D37"
                        : "#1E5496",
                    color: "#FFF",
                    textDecoration: "none",
                    borderRadius: "0 0px 3px 3px",
                } },
                "License (",
                license_count,
                ")")),
        React.createElement(Link, { href: "/abs-broker/show-documents/".concat(broker.user.id) },
            React.createElement(Button, { type: "button", color: "primary", variant: "outlined", style: {
                    backgroundColor: url.includes("/abs-broker/show-documents/")
                        ? "#E21D37"
                        : "#1E5496",
                    color: "#FFF",
                    textDecoration: "none",
                    borderRadius: "0 0px 3px 3px",
                } },
                "Documents (",
                document_count,
                ")"))));
};
export default BrokerNav;
