var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import Grid from "@mui/material/Unstable_Grid2";
import React from "react";
import { router } from "@inertiajs/react";
import route from "ziggy-js";
import GenericControllerTextInput from "../../../components/GenericControllerTextInput";
import { useForm } from "react-hook-form";
import GenericControllerSelectCheckbox from "../../../components/GenericControllerSelectCheckbox";
import GenericAutoComplete from "../../../components/GenericAutoComplete";
import DefaultBtn from "../../../components/DefaultBtn";
export default function ExecutiveForm(_a) {
    var _b, _c;
    var data = _a.data;
    var form_data = data.form_data, serverErrors = data.serverErrors, utmContentOptions = data.utm_contents;
    var reportForm = {
        startDate: form_data === null || form_data === void 0 ? void 0 : form_data.start_date,
        endDate: form_data === null || form_data === void 0 ? void 0 : form_data.end_date,
        memberOrderType: (_b = form_data === null || form_data === void 0 ? void 0 : form_data.member_order_type) !== null && _b !== void 0 ? _b : Array(),
        utm_contents: (_c = form_data === null || form_data === void 0 ? void 0 : form_data.utm_contents) !== null && _c !== void 0 ? _c : Array(),
    };
    var _d = useForm(), handleSubmit = _d.handleSubmit, errors = _d.formState.errors, control = _d.control;
    var memberOrderTypes = [
        {
            id: "0",
            name: "New Members",
        },
        {
            id: "1",
            name: "Renewal",
        },
    ];
    for (var key in serverErrors) {
        errors[key] = serverErrors[key];
    }
    var onSubmit = function (data) {
        router.post(route("executive"), data);
    };
    return (React.createElement(Grid, { container: true, xs: 12, spacing: 2 },
        React.createElement(Grid, { xs: 12, lg: 2 },
            React.createElement(GenericControllerTextInput, { control: control, type: "date", label: "Start Date", name: "start_date", defaultValue: reportForm.startDate, error: "start_date" in errors, shrinkLabel: true })),
        React.createElement(Grid, { xs: 12, lg: 2 },
            React.createElement(GenericControllerTextInput, { control: control, type: "date", label: "End Date", name: "end_date", defaultValue: reportForm.endDate, error: "end_date" in errors, shrinkLabel: true })),
        React.createElement(Grid, { xs: 12, lg: 2 },
            React.createElement(GenericControllerSelectCheckbox, { name: "member_order_type", control: control, label: "Member Order Type", options: memberOrderTypes, rules: {
                    required: false,
                }, error: "member_order_type" in errors, value: reportForm.memberOrderType })),
        React.createElement(Grid, { xs: 12, lg: 2 },
            React.createElement(GenericAutoComplete, { name: "utm_contents", label: "UTM Contents", options: utmContentOptions.map(function (el) { return (__assign(__assign({}, el), { id: el.name })); }), control: control, value: reportForm.utm_contents, rules: {
                    required: false,
                }, limitTags: 1, error: "utm_contents" in errors })),
        React.createElement(Grid, { container: true, xs: 12, lg: 1, alignContent: "center" },
            React.createElement(DefaultBtn, { type: "button", onClick: handleSubmit(onSubmit), variant: "contained", color: "primary", fullWidth: true }, "Update"))));
}
