import React, { useEffect, useState } from "react";
import { router } from "@inertiajs/react";
import Grid from "@mui/material/Unstable_Grid2/";
import OfficeLocations from "../components/OfficeLocations";
import BusinessTable from "./BusinessTable";
import ExportButton from "../components/ExportButton";
function Business(_a) {
    var selected_month = _a.selected_month, selected_year = _a.selected_year, location = _a.location, business_by_agents = _a.business_by_agents, formData = _a.formData, department = _a.department, csrf = _a.csrf;
    var today = new Date();
    var _b = useState(today.getMonth()), month = _b[0], setMonth = _b[1];
    var _c = useState(today.getFullYear()), year = _c[0], setYear = _c[1];
    var _d = useState("all"), currentLocation = _d[0], setCurrentLocation = _d[1];
    var _e = useState(false), ready = _e[0], setReady = _e[1];
    var handleSubmit = function () {
        router.post("/production-boards/d/".concat(department, "/b/business"), {
            selected_month: month + 1,
            selected_year: year,
            location: currentLocation,
        });
    };
    useEffect(function () {
        handleSubmit();
        setReady(true);
    }, [currentLocation, month, year, ready]);
    return (React.createElement(Grid, { container: true, xs: 12, spacing: 3 },
        React.createElement(Grid, { xs: 8 },
            React.createElement(OfficeLocations, { setCurrentLocation: setCurrentLocation, currentLocation: currentLocation, department: department })),
        React.createElement(Grid, { xs: 1 },
            React.createElement(ExportButton, { formData: formData || {}, csrf: csrf || "" })),
        React.createElement(Grid, { xs: 9 }, business_by_agents && (React.createElement(BusinessTable, { business_by_agents: business_by_agents, month: month, setMonth: setMonth, year: year, setYear: setYear, department: department })))));
}
export default Business;
