import { router, Link } from "@inertiajs/react";
import { Box, Button, ListItemText, MenuItem, Typography, } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import GenericControllerTextInput from "../../components/GenericControllerTextInput";
import { isEmpty } from "lodash";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import route from "ziggy-js";
import DisplayErrors from "../../components/DisplayErrors";
import Layout from "../Layout";
import GenericControllerCheckboxInput from "../../components/GenericControllerCheckboxInput";
import GenericControllerSelect from "../../components/GenericControllerSelect";
export default function AgentForm(_a) {
    var _b, _c, _d, _e, _f;
    var response_data = _a.response_data, serverErrors = _a.errors;
    var agent = (response_data === null || response_data === void 0 ? void 0 : response_data.agent) || Array();
    var locations = response_data.locations;
    var _g = useState(true), menuOpen = _g[0], toggleMenuOpen = _g[1];
    var _h = useForm(), handleSubmit = _h.handleSubmit, errors = _h.formState.errors, control = _h.control;
    var onSubmit = function (data) {
        if (!agent.id) {
            router.post(route("create_agent"), data);
        }
        else {
            router.post(route("edit_agent", agent.user.id), data);
        }
    };
    return (React.createElement(Layout, { title: "Edit Agent", menuOpen: menuOpen, toggleMenuOpen: toggleMenuOpen },
        React.createElement(Box, { mb: 2 },
            React.createElement(Typography, { variant: "h6", component: "h3" }, agent.id ? "Edit Agent" : "Create Agent"),
            !isEmpty(serverErrors) && (React.createElement(Grid, { container: true, xs: 12, spacing: 2 },
                React.createElement(Grid, { xs: 12 },
                    React.createElement(DisplayErrors, { errors: serverErrors }))))),
        React.createElement("form", { onSubmit: handleSubmit(onSubmit), style: { backgroundColor: "#f8fafc" } },
            React.createElement(Grid, { container: true, xs: 12, spacing: 4 },
                React.createElement(Grid, { xs: 4 },
                    React.createElement(GenericControllerTextInput, { control: control, className: "login-input", variant: "outlined", rules: { required: true }, label: "First Name", type: "text", defaultValue: ((_b = agent === null || agent === void 0 ? void 0 : agent.user) === null || _b === void 0 ? void 0 : _b.first_name) || "", name: "user[first_name]", error: errors.user &&
                            "first_name" in errors.user, fullWidth: true })),
                React.createElement(Grid, { xs: 4 },
                    React.createElement(GenericControllerTextInput, { control: control, className: "login-input", variant: "outlined", label: "Last Name", rules: { required: true }, type: "text", defaultValue: ((_c = agent === null || agent === void 0 ? void 0 : agent.user) === null || _c === void 0 ? void 0 : _c.last_name) || "", name: "user[last_name]", fullWidth: true, error: errors.user &&
                            "last_name" in errors.user })),
                React.createElement(Grid, { xs: 4 },
                    React.createElement(GenericControllerCheckboxInput, { name: "agent[show_tv_production_board]", control: control, defaultChecked: (agent === null || agent === void 0 ? void 0 : agent.show_tv_production_board) ? true : false, label: "Show on TV production Board" }))),
            React.createElement(Grid, { container: true, xs: 12, spacing: 4 },
                React.createElement(Grid, { xs: 4 },
                    React.createElement(GenericControllerTextInput, { control: control, className: "login-input", variant: "outlined", label: "Email", type: "text", rules: { required: true }, defaultValue: ((_d = agent === null || agent === void 0 ? void 0 : agent.user) === null || _d === void 0 ? void 0 : _d.email) || "", name: "user[email]", error: errors.user &&
                            "email" in errors.user, fullWidth: true })),
                React.createElement(Grid, { xs: 4 },
                    React.createElement(GenericControllerTextInput, { control: control, className: "login-input", variant: "outlined", label: "Phone", type: "text", defaultValue: (agent === null || agent === void 0 ? void 0 : agent.phone) || "", name: "agent[phone]", fullWidth: true })),
                React.createElement(Grid, { xs: 4 },
                    React.createElement(GenericControllerTextInput, { control: control, className: "login-input", variant: "outlined", label: "Extension", type: "text", defaultValue: (agent === null || agent === void 0 ? void 0 : agent.extension) || "", name: "agent[extension]", fullWidth: true }))),
            React.createElement(Grid, { container: true, xs: 12, spacing: 4 },
                React.createElement(Grid, { xs: 4 },
                    React.createElement(GenericControllerSelect, { control: control, label: "status", name: "agent[status]", defaultValue: (_e = agent === null || agent === void 0 ? void 0 : agent.status) !== null && _e !== void 0 ? _e : "active", customOptions: [
                            { id: "active", name: "Active" },
                            { id: "deleted", name: "Deleted" },
                        ].map(function (option, idx) { return (React.createElement(MenuItem, { key: "".concat(name, "_").concat(idx), value: option.id },
                            React.createElement(ListItemText, { primary: option.name }))); }) })),
                React.createElement(Grid, { xs: 4 },
                    React.createElement(GenericControllerSelect, { control: control, label: "location", name: "agent[location_id]", defaultValue: (_f = agent === null || agent === void 0 ? void 0 : agent.location_id) !== null && _f !== void 0 ? _f : "1", customOptions: locations.map(function (option, idx) { return (React.createElement(MenuItem, { key: "".concat(name, "_").concat(idx), value: option.id },
                            React.createElement(ListItemText, { primary: option.name }))); }) })),
                React.createElement(Grid, { xs: 4 },
                    React.createElement(GenericControllerTextInput, { name: "agent[signature]", control: control, className: "login-input", variant: "outlined", label: "Agent Signature", multiline: true, type: "text", error: agent["signature"] in errors, defaultValue: (agent === null || agent === void 0 ? void 0 : agent.signature) || "", fullWidth: true }))),
            React.createElement(Grid, { container: true, xs: 12, spacing: 4, alignItems: "center", justifyContent: "center" },
                React.createElement(Grid, { xs: 3 },
                    React.createElement(Button, { component: Link, type: "button", variant: "outlined", fullWidth: true, color: "secondary", href: route("all_agents").toString() }, "Cancel")),
                React.createElement(Grid, { xs: 3 },
                    React.createElement(Button, { type: "submit", variant: "contained", color: "primary", fullWidth: true }, agent.id ? "Update" : "Create"))))));
}
