import React, { useState } from "react";
import { router, Link } from "@inertiajs/react";
import { useForm } from "react-hook-form";
import { Container, Typography, Button, Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/";
import Layout from "../Layout";
import { isEmpty } from "lodash";
import DisplayErrors from "../../components/DisplayErrors";
import route from "ziggy-js";
import GenericControllerCheckboxInput from "../../components/GenericControllerCheckboxInput";
var AbsAssignCarrierForm = function (_a) {
    var _b;
    var response_data = _a.response_data, serverErrors = _a.errors;
    var _c = useState(true), menuOpen = _c[0], toggleMenuOpen = _c[1];
    var _d = useForm(), control = _d.control, handleSubmit = _d.handleSubmit, errors = _d.formState.errors;
    var carriers = response_data.carriers, carrier_ids = response_data.carrier_ids, states = response_data.states, broker = response_data.broker;
    var statusOptions = [
        {
            id: "active",
            name: "Active",
        },
        {
            id: "pending",
            name: "Pending",
        },
        {
            id: "inactive",
            name: "Inactive",
        },
    ];
    var onSubmit = function (data) {
        var _a;
        data.abs_broker_id = broker === null || broker === void 0 ? void 0 : broker.id;
        data.user_id = (_a = broker === null || broker === void 0 ? void 0 : broker.user) === null || _a === void 0 ? void 0 : _a.id;
        router.post("/abs-broker/carriers/assign", data);
    };
    return (React.createElement(Layout, { title: "ABS Add Carrier", menuOpen: menuOpen, toggleMenuOpen: toggleMenuOpen },
        React.createElement(Container, { maxWidth: false },
            React.createElement("form", { onSubmit: handleSubmit(onSubmit), style: { backgroundColor: "#f8fafc" } },
                React.createElement(Grid, { container: true, xs: 12, spacing: 2 },
                    !isEmpty(serverErrors) && (React.createElement(Grid, { xs: 12 },
                        React.createElement(DisplayErrors, { errors: serverErrors }))),
                    React.createElement(Grid, { xs: 12 },
                        React.createElement(Typography, { variant: "h4", component: "h3", align: "center" }, "Assign Carrier")),
                    React.createElement(Grid, { xs: 12 }, Object.keys(carriers).map(function (el) { return (React.createElement(Box, { key: el, mb: 2 },
                        React.createElement(Typography, { variant: "h6" }, states[el].name),
                        React.createElement(Grid, { container: true, spacing: 2 }, carriers[el].map(function (el) {
                            var _a;
                            return (React.createElement(Grid, { key: el.id, xs: 3 },
                                React.createElement(GenericControllerCheckboxInput, { defaultChecked: (_a = carrier_ids.includes(el.id)) !== null && _a !== void 0 ? _a : false, control: control, name: "carriers[".concat(el.id, "]"), color: "primary", label: "".concat(el.product, " / ").concat(el.name) })));
                        })))); })),
                    React.createElement(Grid, { xs: 12, container: true, alignItems: "center", justifyContent: "center", spacing: 2 },
                        React.createElement(Grid, { xs: 12, md: 6, lg: 3 },
                            React.createElement(Button, { component: Link, type: "button", variant: "outlined", fullWidth: true, color: "secondary", href: route("broker_carriers", [
                                    (_b = broker === null || broker === void 0 ? void 0 : broker.user) === null || _b === void 0 ? void 0 : _b.id,
                                ]).toString() }, "Cancel")),
                        React.createElement(Grid, { xs: 12, md: 6, lg: 3 },
                            React.createElement(Button, { type: "button", variant: "contained", color: "primary", onClick: handleSubmit(onSubmit), fullWidth: true }, "Update Carriers"))))))));
};
export default AbsAssignCarrierForm;
