var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useState } from "react";
import { Button, TextField, Typography, FormControlLabel, Checkbox, Modal, Dialog, DialogContent, Alert, AlertTitle, } from "@mui/material";
import { PeopleIcon, MoneyIcon, HandshakeIcon, HeartPulseIcon, FormPaperIcon, PoliciesIcon, PersonIcon, } from "../../components/IconLibrary";
import styled from "styled-components";
import Grid from "@mui/material/Unstable_Grid2/";
import { hasPermission } from "../../utils/utils";
import { startCase, isEmpty } from "lodash";
import { useForm, Controller } from "react-hook-form";
import { router, usePage } from "@inertiajs/react";
import route from "ziggy-js";
import Layout from "../Layout";
import Display from "./Display";
import ReportsNav from "./components/ReportsNav";
import GenericControllerTextInput from "../../components/GenericControllerTextInput";
import GenericControllerSelectCheckbox from "../../components/GenericControllerSelectCheckbox";
import GenericAutoComplete from "../../components/GenericAutoComplete";
import ControllableAutoComplete from "../../components/ControllableAutoComplete";
import ReportDateSelect from "./components/ReportDateSelect";
import GenericIcon from "../../components/GenericIcon";
import GenericControllerCheckboxInput from "../../components/GenericControllerCheckboxInput";
export default function Reports(_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16, _17, _18, _19, _20, _21, _22;
    var stateOptions = _a.states, agentOptions = _a.agents, orderOptions = _a.order_types, leadOptions = _a.lead_types, insuranceTypeOptions = _a.insurance_types, locationOptions = _a.locations, referralOptions = _a.referrals, utmCampaignOptions = _a.utm_campaigns, utmContentOptions = _a.utm_contents, utmObjectiveOptions = _a.utm_objectives, utmMediumOptions = _a.utm_mediums, utmSourceOptions = _a.utm_sources, dispositionOptions = _a.dispositions, serverErrors = _a.errors, csrf = _a.csrf, summary = _a.summary, form_data = _a.form_data;
    var auth = usePage().props.auth;
    var _23 = useState(false), isSubmitting = _23[0], setIsSubmitting = _23[1];
    var _24 = useState(false), donationChecked = _24[0], setDonationChecked = _24[1];
    var handleCloseModal = function () {
        setIsSubmitting(false);
    };
    var reportForm = {
        utm_campaign: (_b = form_data === null || form_data === void 0 ? void 0 : form_data.utm_campaign) !== null && _b !== void 0 ? _b : "",
        usaepay_id: (_c = form_data === null || form_data === void 0 ? void 0 : form_data.usaepay_id) !== null && _c !== void 0 ? _c : "",
        find_by_id: (_d = form_data === null || form_data === void 0 ? void 0 : form_data.find_by_id) !== null && _d !== void 0 ? _d : "",
        find_by_member_id: (_e = form_data === null || form_data === void 0 ? void 0 : form_data.find_by_member_id) !== null && _e !== void 0 ? _e : "",
        find_by_zip: (_f = form_data === null || form_data === void 0 ? void 0 : form_data.find_by_zip) !== null && _f !== void 0 ? _f : "",
        find_by_congressional_district: (_g = form_data === null || form_data === void 0 ? void 0 : form_data.find_by_congressional_district) !== null && _g !== void 0 ? _g : "",
        start_date: (_h = form_data === null || form_data === void 0 ? void 0 : form_data.start_date) !== null && _h !== void 0 ? _h : "",
        end_date: (_j = form_data === null || form_data === void 0 ? void 0 : form_data.end_date) !== null && _j !== void 0 ? _j : "",
        card_start_date: (_k = form_data === null || form_data === void 0 ? void 0 : form_data.card_start_date) !== null && _k !== void 0 ? _k : "",
        card_end_date: (_l = form_data === null || form_data === void 0 ? void 0 : form_data.card_end_date) !== null && _l !== void 0 ? _l : "",
        prev_exp_start_date: (_m = form_data === null || form_data === void 0 ? void 0 : form_data.prev_exp_start_date) !== null && _m !== void 0 ? _m : "",
        prev_exp_end_date: (_o = form_data === null || form_data === void 0 ? void 0 : form_data.prev_exp_end_date) !== null && _o !== void 0 ? _o : "",
        exp_start_date: (_p = form_data === null || form_data === void 0 ? void 0 : form_data.exp_start_date) !== null && _p !== void 0 ? _p : "",
        exp_end_date: (_q = form_data === null || form_data === void 0 ? void 0 : form_data.exp_end_date) !== null && _q !== void 0 ? _q : "",
        since_start_date: (_r = form_data === null || form_data === void 0 ? void 0 : form_data.since_start_date) !== null && _r !== void 0 ? _r : "",
        since_end_date: (_s = form_data === null || form_data === void 0 ? void 0 : form_data.since_end_date) !== null && _s !== void 0 ? _s : "",
        dob_start_date: (_t = form_data === null || form_data === void 0 ? void 0 : form_data.dob_start_date) !== null && _t !== void 0 ? _t : "",
        dob_end_date: (_u = form_data === null || form_data === void 0 ? void 0 : form_data.dob_end_date) !== null && _u !== void 0 ? _u : "",
        follow_start_date: (_v = form_data === null || form_data === void 0 ? void 0 : form_data.follow_start_date) !== null && _v !== void 0 ? _v : "",
        follow_end_date: (_w = form_data === null || form_data === void 0 ? void 0 : form_data.follow_end_date) !== null && _w !== void 0 ? _w : "",
        cancel_start_date: (_x = form_data === null || form_data === void 0 ? void 0 : form_data.cancel_start_date) !== null && _x !== void 0 ? _x : "",
        cancel_end_date: (_y = form_data === null || form_data === void 0 ? void 0 : form_data.cancel_end_date) !== null && _y !== void 0 ? _y : "",
        memberOrderType: (_z = form_data === null || form_data === void 0 ? void 0 : form_data.member_order_type) !== null && _z !== void 0 ? _z : Array(),
        has_donation: (_0 = form_data === null || form_data === void 0 ? void 0 : form_data.has_donation) !== null && _0 !== void 0 ? _0 : Array(),
        status: (_1 = form_data === null || form_data === void 0 ? void 0 : form_data.status) !== null && _1 !== void 0 ? _1 : Array(),
        orderStatus: (_2 = form_data === null || form_data === void 0 ? void 0 : form_data.orderStatus) !== null && _2 !== void 0 ? _2 : Array(),
        report: (_3 = form_data === null || form_data === void 0 ? void 0 : form_data.report) !== null && _3 !== void 0 ? _3 : "default",
        order_types: (_4 = form_data === null || form_data === void 0 ? void 0 : form_data.order_types) !== null && _4 !== void 0 ? _4 : Array(),
        lead_types: (_5 = form_data === null || form_data === void 0 ? void 0 : form_data.lead_types) !== null && _5 !== void 0 ? _5 : Array(),
        insurance_types: (_6 = form_data === null || form_data === void 0 ? void 0 : form_data.insurance_types) !== null && _6 !== void 0 ? _6 : Array(),
        user_types: (_7 = form_data === null || form_data === void 0 ? void 0 : form_data.user_types) !== null && _7 !== void 0 ? _7 : Array(),
        disposition: (_8 = form_data === null || form_data === void 0 ? void 0 : form_data.disposition) !== null && _8 !== void 0 ? _8 : Array(),
        filter_by: (_9 = form_data === null || form_data === void 0 ? void 0 : form_data.filter_by) !== null && _9 !== void 0 ? _9 : Array("created_date"),
        states: (_10 = form_data === null || form_data === void 0 ? void 0 : form_data.states) !== null && _10 !== void 0 ? _10 : Array(),
        agents: (_11 = form_data === null || form_data === void 0 ? void 0 : form_data.agents) !== null && _11 !== void 0 ? _11 : Array(),
        locations: (_12 = form_data === null || form_data === void 0 ? void 0 : form_data.locations) !== null && _12 !== void 0 ? _12 : Array(),
        created_by: (_13 = form_data === null || form_data === void 0 ? void 0 : form_data.created_by) !== null && _13 !== void 0 ? _13 : Array(),
        transferred_to: (_14 = form_data === null || form_data === void 0 ? void 0 : form_data.transferred_to) !== null && _14 !== void 0 ? _14 : Array(),
        referrals: (_15 = form_data === null || form_data === void 0 ? void 0 : form_data.referrals) !== null && _15 !== void 0 ? _15 : Array(),
        utm_campaigns: (_16 = form_data === null || form_data === void 0 ? void 0 : form_data.utm_campaigns) !== null && _16 !== void 0 ? _16 : Array(),
        utm_contents: (_17 = form_data === null || form_data === void 0 ? void 0 : form_data.utm_contents) !== null && _17 !== void 0 ? _17 : Array(),
        utm_objectives: (_18 = form_data === null || form_data === void 0 ? void 0 : form_data.utm_objectives) !== null && _18 !== void 0 ? _18 : Array(),
        utm_mediums: (_19 = form_data === null || form_data === void 0 ? void 0 : form_data.utm_mediums) !== null && _19 !== void 0 ? _19 : Array(),
        utm_sources: (_20 = form_data === null || form_data === void 0 ? void 0 : form_data.utm_sources) !== null && _20 !== void 0 ? _20 : Array(),
        min_donation_amount: (_21 = form_data === null || form_data === void 0 ? void 0 : form_data.min_donation_amount) !== null && _21 !== void 0 ? _21 : "",
        max_donation_amount: (_22 = form_data === null || form_data === void 0 ? void 0 : form_data.max_donation_amount) !== null && _22 !== void 0 ? _22 : "",
    };
    var _25 = useState(true), menuOpen = _25[0], toggleMenuOpen = _25[1];
    var _26 = useState(true), showMenu = _26[0], toggleShowMenu = _26[1];
    var _27 = useState(false), showErrors = _27[0], setShowErrors = _27[1];
    var _28 = useState(reportForm.report), report = _28[0], setReport = _28[1];
    var _29 = useForm(), control = _29.control, handleSubmit = _29.handleSubmit, getFieldState = _29.getFieldState, errors = _29.formState.errors, getValues = _29.getValues, setValue = _29.setValue, watch = _29.watch, reset = _29.reset;
    var utmCampaign = reportForm.utm_campaign;
    var usaepayId = reportForm.usaepay_id;
    var findById = reportForm.find_by_id;
    var findByMemberId = reportForm.find_by_member_id;
    var findByZip = reportForm.find_by_zip;
    var findByCongressionalDistrict = reportForm.find_by_congressional_district;
    var status = reportForm.status;
    var hasDonation = reportForm.has_donation;
    var orderStatus = reportForm.orderStatus;
    var states = reportForm.states;
    var agents = reportForm.agents;
    var created_by = reportForm.created_by;
    var transferred_to = reportForm.transferred_to;
    var referrals = reportForm.referrals;
    var utmContents = reportForm.utm_contents;
    var utmObjectives = reportForm.utm_objectives;
    // const utmCampaigns = reportForm.utm_campaigns;
    var utmMediums = reportForm.utm_mediums;
    var utmSources = reportForm.utm_sources;
    var order_types = reportForm.order_types;
    var lead_types = reportForm.lead_types;
    var insurance_types = reportForm.insurance_types;
    var user_types = reportForm.user_types;
    var locations = reportForm.locations;
    var disposition = reportForm.disposition;
    var memberOrderType = reportForm.memberOrderType;
    var minDonationAmount = reportForm.min_donation_amount;
    var maxDonationAmount = reportForm.max_donation_amount;
    var filterBy = watch("filter_by", reportForm.filter_by);
    var memberOrderTypes = [
        {
            id: "0",
            name: "New Members",
        },
        {
            id: "1",
            name: "Renewal",
        },
    ];
    var userTypes = [
        {
            id: "App\\Models\\Agent",
            name: "Agents",
        },
        {
            id: "App\\Models\\AbsBroker",
            name: "ABS Agents",
        },
    ];
    var reportTypes = [
        {
            id: "members",
            name: "Members",
            icon: React.createElement(PeopleIcon, null),
            access: "run member reports",
        },
        {
            id: "orders",
            name: "Orders",
            icon: React.createElement(MoneyIcon, null),
            access: "run order reports",
        },
        {
            id: "leads",
            name: "Leads",
            icon: React.createElement(HandshakeIcon, null),
            access: "run lead reports",
        },
        {
            id: "health_applications",
            name: "Health Applications",
            icon: React.createElement(HeartPulseIcon, null),
            access: "run health_app reports",
        },
        {
            id: "life_applications",
            name: "Life Applications",
            icon: React.createElement(FormPaperIcon, null),
            access: "run life_app reports",
        },
        {
            id: "policies",
            name: "Policies",
            icon: React.createElement(PoliciesIcon, null),
            access: "run policy reports",
        },
        {
            id: "merchandises",
            name: "Merchandises",
            icon: React.createElement(MoneyIcon, null),
            access: "run order reports",
        },
        {
            id: "donations",
            name: "donations",
            icon: React.createElement(PersonIcon, null),
            access: "run donation reports",
        },
    ];
    var orderStatuses = [
        { id: "successful", name: "successful" },
        { id: "refunded", name: "refunded" },
        { id: "failed", name: "failed" },
        { id: "deleted", name: "deleted" },
    ];
    var statuses = [
        {
            id: "active",
            name: "Active",
        },
        {
            id: "pending",
            name: "Pending",
        },
        {
            id: "prospect",
            name: "Prospect",
        },
        { id: "deleted", name: "Deleted" },
    ];
    hasPermission(auth, "run unmarketable_member reports")
        ? statuses.push({ id: "unmarketable", name: "Unmarketable" })
        : null;
    var filterOptions = [
        { id: "lifetime", name: "Lifetime" },
        { id: "mailing_list", name: "Mailing List" },
        { id: "magazine_list", name: "Magazine List" },
        { id: "agents", name: "Agents" },
        { id: "member_since", name: "Member Since" },
        { id: "membership_cancellation", name: "Membership Cancellation" },
        { id: "membership_expiration", name: "Membership Expiration" },
        {
            id: "membership_prev_expiration",
            name: "Membership Previous Expiration",
        },
        { id: "send_cards", name: "Send Cards" },
        { id: "created_date", name: "Created Date" },
        { id: "dob", name: "Birthday" },
        { id: "no_email", name: "No Email" },
        { id: "email", name: "Email" },
        { id: "states", name: "States" },
    ];
    for (var key in serverErrors) {
        errors[key] = serverErrors[key];
    }
    var onSubmit = function (data) {
        data.export_name = data.report;
        data.export_type = ".csv";
        router.post(route("run-report"), data);
    };
    useEffect(function () {
        var _a;
        if ((summary === null || summary === void 0 ? void 0 : summary.total) > 0) {
            toggleShowMenu(!showMenu);
        }
        setShowErrors((_a = (summary === null || summary === void 0 ? void 0 : summary.total) === 0) !== null && _a !== void 0 ? _a : false);
    }, [summary]);
    useEffect(function () {
        if (!isEmpty(serverErrors)) {
            setIsSubmitting(false);
        }
    }, [serverErrors]);
    var toggleHasDonation = function () {
        var next_has_donation_value = !donationChecked;
        setDonationChecked(next_has_donation_value);
        next_has_donation_value === true
            ? setValue("unmarketable_status", next_has_donation_value)
            : null;
    };
    return (React.createElement(Layout, { title: "Report", menuOpen: menuOpen, toggleMenuOpen: toggleMenuOpen },
        showMenu && (React.createElement(Grid, { container: true, spacing: 3 },
            report === "default" && (React.createElement(React.Fragment, null,
                React.createElement(Grid, { xs: 9 },
                    React.createElement(Typography, { variant: "h5", component: "h2" }, "Reports")),
                React.createElement(Grid, { spacing: 0, xs: 6, container: true }, reportTypes.map(function (reportType) {
                    if (!hasPermission(auth, reportType.access)) {
                        return null;
                    }
                    return (React.createElement(Grid, { key: reportType.id },
                        React.createElement(GenericIcon, { id: reportType.id, title: reportType.name, icon: reportType.icon, handleClick: function () {
                                setReport(reportType.id);
                            } })));
                })))),
            report !== "default" && (React.createElement(React.Fragment, null,
                React.createElement(Grid, { xs: 12 },
                    React.createElement(ReportsNav, { reportTypes: reportTypes, report: report, handleClick: function (id) {
                            reset({ report: id });
                            setShowErrors(false);
                            setReport(id);
                        } }),
                    React.createElement(Typography, { variant: "h5", component: "h2" },
                        startCase(report),
                        " Reports"),
                    React.createElement(Controller, { control: control, name: "report", defaultValue: report, render: function (_a) {
                            var field = _a.field;
                            return (React.createElement(TextField, { hidden: true, error: "report" in errors }));
                        } }),
                    React.createElement(Button, { type: "button", onClick: function () {
                            reset();
                        }, color: "secondary", variant: "outlined" }, "Reset Form")),
                showErrors && (React.createElement(Grid, { xs: 12 },
                    React.createElement(Alert, { severity: "warning" },
                        React.createElement(AlertTitle, null, "No Result"),
                        "Your search returned without any results."))),
                [
                    "leads",
                    "health_applications",
                    "life_applications",
                    "policies",
                ].includes(report) && (React.createElement(React.Fragment, null,
                    [
                        "leads",
                        "health_applications",
                        "life_applications",
                    ].includes(report) && (React.createElement(Grid, { xs: 12, md: 6 },
                        React.createElement(GenericControllerSelectCheckbox, { name: "disposition", control: control, label: "Disposition", options: dispositionOptions, rules: {
                                required: false,
                            }, error: "disposition" in errors, value: disposition }))),
                    [
                        "leads",
                        "health_applications",
                        "policies",
                    ].includes(report) && (React.createElement(React.Fragment, null,
                        React.createElement(Grid, { xs: 12, md: 6 },
                            React.createElement(GenericControllerSelectCheckbox, { name: "lead_types", control: control, label: "Lead Types", options: leadOptions, rules: {
                                    required: false,
                                }, error: "lead_types" in errors, value: lead_types })),
                        React.createElement(Grid, { xs: 12, md: 6 },
                            React.createElement(GenericAutoComplete, { name: "transferred_to", label: "Transferred To", options: agentOptions, control: control, value: transferred_to, placeholder: "Select Agent", rules: {
                                    required: false,
                                }, error: "transferred_to" in
                                    errors, limitTags: 3 })))),
                    ["leads", "policies"].includes(report) && (React.createElement(Grid, { xs: 12, md: 6 },
                        React.createElement(GenericControllerSelectCheckbox, { name: "insurance_types", control: control, label: "Insurance Types", options: insuranceTypeOptions, rules: {
                                required: false,
                            }, error: "insurance_types" in errors, value: insurance_types }))),
                    React.createElement(Grid, { xs: 12, md: 6 },
                        React.createElement(GenericAutoComplete, { name: "user_types", label: "Agent Type", options: userTypes, control: control, value: user_types, placeholder: "Select Agent Type", rules: {
                                required: false,
                            }, error: "user_types" in errors, limitTags: 3 })),
                    React.createElement(Grid, { xs: 12, md: 6 },
                        React.createElement(GenericAutoComplete, { name: "created_by", label: "Created By", options: agentOptions, control: control, value: created_by, placeholder: "Select Agent", rules: {
                                required: false,
                            }, error: "created_by" in errors, limitTags: 3 })),
                    React.createElement(Grid, { xs: 12, md: 6 },
                        React.createElement(GenericControllerSelectCheckbox, { name: "locations", label: "Locations", options: locationOptions, control: control, value: locations, rules: {
                                required: false,
                            }, error: "locations" in errors })))),
                ["orders"].includes(report) && (React.createElement(React.Fragment, null,
                    React.createElement(Grid, { xs: 12, md: 6 },
                        React.createElement(GenericControllerSelectCheckbox, { name: "order_types", control: control, label: "Order Types", options: orderOptions, rules: {
                                required: false,
                            }, error: "order_types" in errors, value: order_types })),
                    React.createElement(Grid, { xs: 12, lg: 6 },
                        React.createElement(GenericControllerSelectCheckbox, { name: "member_order_type", control: control, label: "Member Order Type", options: memberOrderTypes, rules: {
                                required: false,
                            }, error: "member_order_type" in errors, value: memberOrderType })))),
                ["orders"].includes(report) && (React.createElement(React.Fragment, null,
                    React.createElement(Grid, { xs: 12, md: 6 },
                        React.createElement(ControllableAutoComplete, { name: "referrals", label: "Referrals", options: referralOptions, control: control, value: referrals, placeholder: "Select Referral", rules: {
                                required: false,
                            }, error: "referrals" in errors, limitTags: 3, disableCloseOnSelect: true })),
                    React.createElement(Grid, { xs: 12, md: 6 },
                        React.createElement(GenericControllerTextInput, { control: control, label: "Transaction ID", type: "text", rules: { required: false }, name: "usaepay_id", error: "usaepay_id" in errors, defaultValue: usaepayId })),
                    React.createElement(Grid, { xs: 12, md: 6 },
                        React.createElement(GenericControllerSelectCheckbox, { name: "orderStatus", control: control, label: "Status", options: orderStatuses, rules: {
                                required: false,
                            }, error: "orderStatus" in errors, value: orderStatus })))),
                ["donations"].includes(report) && (React.createElement(React.Fragment, null,
                    React.createElement(Grid, { xs: 6, md: 3 },
                        React.createElement(GenericControllerTextInput, { label: "Min Donation Amount", type: "number", control: control, rules: { required: false }, name: "min_donation_amount", error: "min_donation_amount" in errors, defaultValue: minDonationAmount })),
                    React.createElement(Grid, { xs: 6, md: 3 },
                        React.createElement(GenericControllerTextInput, { label: "Max Donation Amount", type: "number", control: control, rules: { required: false }, name: "max_donation_amount", error: "max_donation_amount" in errors, defaultValue: maxDonationAmount })),
                    React.createElement(Grid, { xs: 12, md: 6 },
                        React.createElement(GenericAutoComplete, { name: "states", label: "States", options: stateOptions, control: control, value: states, placeholder: "Select State", rules: {
                                required: false,
                            }, error: "states" in errors, limitTags: 3 })))),
                [
                    "orders",
                    "members",
                    "leads",
                    "health_applications",
                    "life_applications",
                    "policies",
                    "donations",
                ].includes(report) && (React.createElement(React.Fragment, null,
                    React.createElement(Grid, { xs: 12, md: 6 },
                        React.createElement(GenericAutoComplete, { name: "utm_objectives", label: "UTM Objectives", options: utmObjectiveOptions[["orders", "members"].includes(report)
                                ? "members"
                                : report === "donations"
                                    ? "donations"
                                    : "leads"].map(function (el, index) { return (__assign(__assign({}, el), { id: index + 1 })); }), control: control, value: utmObjectives, placeholder: "Select UTM Objectives", rules: {
                                required: false,
                            }, error: "utm_objectives" in errors, limitTags: 3 })),
                    React.createElement(Grid, { xs: 12, md: 6 },
                        React.createElement(GenericAutoComplete, { name: "utm_sources", label: "UTM Sources", options: utmSourceOptions[["orders", "members"].includes(report)
                                ? "members"
                                : report === "donations"
                                    ? "donations"
                                    : "leads"].map(function (el, index) { return (__assign(__assign({}, el), { id: index + 1 })); }), control: control, value: utmSources, placeholder: "Select UTM Sources", rules: {
                                required: false,
                            }, error: "utm_sources" in errors, limitTags: 3 })),
                    React.createElement(Grid, { xs: 12, md: 6 },
                        React.createElement(GenericAutoComplete, { name: "utm_mediums", label: "UTM Mediums", options: utmMediumOptions[["orders", "members"].includes(report)
                                ? "members"
                                : report === "donations"
                                    ? "donations"
                                    : "leads"].map(function (el, index) { return (__assign(__assign({}, el), { id: index + 1 })); }), control: control, value: utmMediums, placeholder: "Select UTM Medium", rules: {
                                required: false,
                            }, error: "utm_mediums" in errors, limitTags: 3 })),
                    React.createElement(Grid, { xs: 12, md: 6 },
                        React.createElement(GenericAutoComplete, { name: "utm_contents", label: "UTM Contents", options: utmContentOptions[["orders", "members"].includes(report)
                                ? "members"
                                : report === "donations"
                                    ? "donations"
                                    : "leads"].map(function (el, index) { return (__assign(__assign({}, el), { id: index + 1 })); }), control: control, value: utmContents, placeholder: "Select UTM Contents", rules: {
                                required: false,
                            }, error: "utm_contents" in errors, limitTags: 3 })),
                    React.createElement(Grid, { xs: 12, md: 6 },
                        React.createElement(GenericControllerTextInput, { label: "UTM Campaign", type: "text", control: control, rules: { required: false }, name: "utm_campaign", error: "utm_campaign" in errors, defaultValue: utmCampaign })))),
                React.createElement(Grid, { xs: 12, md: 6 },
                    React.createElement(GenericControllerTextInput, { control: control, label: "Find By ID", multiline: true, type: "text", rules: { required: false }, name: "find_by_id", error: "find_by_id" in errors, defaultValue: findById, minRows: 1 })),
                ["orders"].includes(report) && (React.createElement(React.Fragment, null,
                    React.createElement(Grid, { xs: 12, md: 6 },
                        React.createElement(GenericControllerTextInput, { control: control, label: "Find By Member ID", multiline: true, type: "text", rules: { required: false }, name: "find_by_member_id", error: "find_by_member_id" in errors, defaultValue: findByMemberId, minRows: 1 })))),
                ["members"].includes(report) && (React.createElement(React.Fragment, null,
                    React.createElement(Grid, { xs: 12, md: 6 },
                        React.createElement(GenericControllerTextInput, { control: control, label: "Find By Congressional District", multiline: true, type: "text", rules: { required: false }, name: "find_by_congressional_district", error: "find_by_congressional_district" in
                                errors, defaultValue: findByCongressionalDistrict, minRows: 1 })))),
                ["members"].includes(report) && (React.createElement(React.Fragment, null,
                    React.createElement(Grid, { xs: 12, md: 6 },
                        React.createElement(GenericControllerTextInput, { control: control, label: "Find By Zip", multiline: true, type: "text", rules: { required: false }, name: "find_by_zip", error: "find_by_zip" in errors, defaultValue: findByZip, minRows: 1 })),
                    React.createElement(Grid, { xs: 12, md: 6 },
                        React.createElement(GenericControllerSelectCheckbox, { name: "status", control: control, label: "Status", options: statuses, rules: {
                                required: false,
                            }, error: "status" in errors, value: status })),
                    React.createElement(Grid, { xs: 12, md: 6 },
                        React.createElement(GenericControllerSelectCheckbox, { name: "filter_by", label: "Filter By", options: filterOptions, value: filterBy, control: control })),
                    filterBy.includes("agents") && (React.createElement(Grid, { xs: 12, md: 6 },
                        React.createElement(GenericAutoComplete, { name: "agents", label: "Agents", options: agentOptions, control: control, value: agents, placeholder: "Select Agent", rules: {
                                required: true,
                                validate: function () {
                                    return getValues("agents")
                                        .length > 0;
                                },
                            }, error: "agents" in errors, limitTags: 3 }))),
                    filterBy.includes("states") && (React.createElement(Grid, { xs: 12, md: 6 },
                        React.createElement(GenericAutoComplete, { name: "states", label: "States", options: stateOptions, control: control, value: states, placeholder: "Select State", rules: {
                                required: true,
                                validate: function () {
                                    return getValues("states")
                                        .length > 0;
                                },
                            }, error: "states" in errors, limitTags: 3 }))),
                    filterBy.includes("dob") && (React.createElement(ReportDateSelect, { reportForm: reportForm, errors: errors, title: "".concat(startCase(report), " Birthday"), startLabel: "Start Date", endLabel: "End Date", startName: "dob_start_date", endName: "dob_end_date", startControl: control, endControl: control, required: true })),
                    filterBy.includes("member_since") && (React.createElement(ReportDateSelect, { reportForm: reportForm, errors: errors, title: "".concat(startCase(report), " Since"), startLabel: "Start Date", endLabel: "End Date", startName: "since_start_date", endName: "since_end_date", startControl: control, endControl: control, required: true })),
                    filterBy.includes("membership_cancellation") && (React.createElement(ReportDateSelect, { reportForm: reportForm, errors: errors, title: "".concat(startCase(report), " Cancellation"), startLabel: "Start Date", endLabel: "End Date", startName: "cancel_start_date", endName: "cancel_end_date", startControl: control, endControl: control, required: true })),
                    filterBy.includes("membership_expiration") && (React.createElement(ReportDateSelect, { reportForm: reportForm, errors: errors, title: "".concat(startCase(report), " Expiration"), startLabel: "Start Date", endLabel: "End Date", startName: "exp_start_date", endName: "exp_end_date", startControl: control, endControl: control, required: true })),
                    filterBy.includes("membership_prev_expiration") && (React.createElement(ReportDateSelect, { reportForm: reportForm, errors: errors, title: "".concat(startCase(report), " Previous Expiration"), startLabel: "Start Date", endLabel: "End Date", startName: "prev_exp_start_date", endName: "prev_exp_end_date", startControl: control, endControl: control, required: true })),
                    filterBy.includes("send_cards") && (React.createElement(ReportDateSelect, { reportForm: reportForm, errors: errors, title: "".concat(startCase(report), " Send Cards"), startLabel: "Start Date", endLabel: "End Date", startName: "card_start_date", endName: "card_end_date", startControl: control, endControl: control, required: true })),
                    filterBy.includes("created_date") && (React.createElement(ReportDateSelect, { reportForm: reportForm, errors: errors, title: "".concat(startCase(report), " Created Date"), startLabel: "Start Date", endLabel: "End Date", startName: "start_date", endName: "end_date", startControl: control, endControl: control, required: true })),
                    React.createElement(Grid, { xs: 6, md: 3 },
                        React.createElement(GenericControllerCheckboxInput, { name: "show_latest_order", control: control, defaultChecked: false, label: "Include Latest Order Date (For reports less than 2 years)", color: "primary" })),
                    ["members"].includes(report) && (React.createElement(React.Fragment, null,
                        hasPermission(auth, "run unmarketable_member reports") ? (React.createElement(React.Fragment, null,
                            React.createElement(FormControlLabel, { label: "Show AMAC Action Donors only", control: React.createElement(Controller, { name: "has_donation", control: control, defaultValue: false, render: function (_a) {
                                        var _b = _a.field, onChange = _b.onChange, onBlur = _b.onBlur, value = _b.value, error = _a.fieldState.error;
                                        return (React.createElement(Checkbox, { onChange: function (e) {
                                                toggleHasDonation();
                                                onChange(e);
                                            }, checked: getValues("has_donation"), value: value }));
                                    } }) }),
                            React.createElement(FormControlLabel, { label: "Include unmarketable members", control: React.createElement(Controller, { name: "unmarketable_status", control: control, defaultValue: false, render: function (_a) {
                                        var _b = _a.field, onChange = _b.onChange, onBlur = _b.onBlur, value = _b.value, error = _a.fieldState.error;
                                        return (React.createElement(Checkbox, { onChange: onChange, checked: getValues("unmarketable_status"), value: value }));
                                    } }) }))) : null,
                        " ",
                        React.createElement(Grid, { xs: 6, md: 3 },
                            React.createElement(GenericControllerCheckboxInput, { name: "cancelled_status", control: control, defaultChecked: false, label: "Include cancelled members", color: "primary" })))))),
                !["members", "donations"].includes(report) && (React.createElement(ReportDateSelect, { reportForm: reportForm, errors: errors, title: "".concat(startCase(report), " Create Date"), startLabel: "Start Date", endLabel: "End Date", startName: "start_date", endName: "end_date", startControl: control, endControl: control, required: true })),
                ["donations"].includes(report) && (React.createElement(ReportDateSelect, { reportForm: reportForm, errors: errors, title: "Donation Date", startLabel: "Start Date", endLabel: "End Date", startName: "start_date", endName: "end_date", startControl: control, endControl: control, required: true })),
                [
                    "leads",
                    "health_applications",
                    "life_applications",
                ].includes(report) && (React.createElement(ReportDateSelect, { reportForm: reportForm, errors: errors, title: "".concat(startCase(report), " Follow-up Date"), startLabel: "Start Date", endLabel: "End Date", startName: "follow_start_date", endName: "follow_end_date", startControl: control, endControl: control })),
                React.createElement(Grid, { container: true, xs: 12, justifyContent: "center" },
                    React.createElement(Grid, { xs: 3 },
                        React.createElement(Button, { type: "button", variant: "contained", color: "primary", onClick: handleSubmit(onSubmit), fullWidth: true }, "Run Report"))))))),
        !showMenu && (React.createElement(Display, { csrf: csrf, summary: summary, formData: form_data, handleToggleMenu: function () {
                toggleShowMenu(!showMenu);
            } })),
        React.createElement(Modal, { open: isSubmitting, onClose: handleCloseModal },
            React.createElement(Dialog, { open: isSubmitting, onClose: handleCloseModal },
                React.createElement(DialogContent, { style: { minWidth: "500px", textAlign: "center" } }, "This form is being submitted")))));
}
var FixedWidthGrid = styled(Grid)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    max-width: 1000px;\n"], ["\n    max-width: 1000px;\n"])));
var templateObject_1;
