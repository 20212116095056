import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Container, Typography, Button, MenuItem } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/";
import Layout from "../Layout";
import { isEmpty } from "lodash";
import DisplayErrors from "../../components/DisplayErrors";
import GenericControllerTextInput from "../../components/GenericControllerTextInput";
import GenericControllerDropdownInput from "../../components/GenericControllerDropdownInput";
import { router, Link } from "@inertiajs/react";
import route from "ziggy-js";
var AbsCarrierForm = function (_a) {
    var _b, _c, _d, _e;
    var response_data = _a.response_data, serverErrors = _a.errors;
    var _f = useState(true), menuOpen = _f[0], toggleMenuOpen = _f[1];
    var _g = useForm(), control = _g.control, handleSubmit = _g.handleSubmit, errors = _g.formState.errors;
    var carrier = response_data.carrier, states = response_data.states, broker = response_data.broker;
    var _h = useState((_b = carrier === null || carrier === void 0 ? void 0 : carrier.state_id) !== null && _b !== void 0 ? _b : ""), carrierState = _h[0], setCarrierState = _h[1];
    var statusOptions = [
        {
            id: "active",
            name: "Active",
        },
        {
            id: "pending",
            name: "Pending",
        },
        {
            id: "inactive",
            name: "Inactive",
        },
    ];
    var onSubmit = function (data) {
        var _a;
        data.abs_broker_id = broker === null || broker === void 0 ? void 0 : broker.id;
        data.user_id = (_a = broker === null || broker === void 0 ? void 0 : broker.user) === null || _a === void 0 ? void 0 : _a.id;
        if (carrier === null || carrier === void 0 ? void 0 : carrier.id) {
            data.license_id = carrier === null || carrier === void 0 ? void 0 : carrier.id;
            router.post("/abs-broker/edit-carrier/".concat(carrier === null || carrier === void 0 ? void 0 : carrier.id), data);
        }
        else {
            router.post("/abs-broker/create-carrier", data);
        }
    };
    return (React.createElement(Layout, { title: "ABS Add Carrier", menuOpen: menuOpen, toggleMenuOpen: toggleMenuOpen },
        React.createElement(Container, { maxWidth: false },
            React.createElement("form", { onSubmit: handleSubmit(onSubmit), style: { backgroundColor: "#f8fafc" } },
                React.createElement(Grid, { container: true, xs: 12, spacing: 2 },
                    !isEmpty(serverErrors) && (React.createElement(Grid, { xs: 12 },
                        React.createElement(DisplayErrors, { errors: serverErrors }))),
                    React.createElement(Grid, { xs: 12 },
                        React.createElement(Typography, { variant: "h4", component: "h3", align: "center" }, (carrier === null || carrier === void 0 ? void 0 : carrier.id)
                            ? "Edit ABS Carrier"
                            : "New ABS Carrier")),
                    React.createElement(Grid, { xs: 12, md: 6 },
                        React.createElement(GenericControllerDropdownInput, { label: "State", name: "state_id", defaultValue: carrierState, id: "state_select", handleChange: function (e) {
                                return setCarrierState(e.target.value);
                            }, rules: {
                                required: true,
                            }, control: control, error: "state_id" in errors, options: !!(states === null || states === void 0 ? void 0 : states.length) &&
                                states.map(function (state) { return (React.createElement(MenuItem, { key: state.id, value: state.id }, state.name)); }) })),
                    React.createElement(Grid, { xs: 12, md: 6 },
                        React.createElement(GenericControllerTextInput, { name: "product", label: "Product", control: control, rules: {
                                required: true,
                            }, defaultValue: (_c = carrier === null || carrier === void 0 ? void 0 : carrier.product) !== null && _c !== void 0 ? _c : "", error: "product" in errors })),
                    React.createElement(Grid, { xs: 12, md: 6 },
                        React.createElement(GenericControllerTextInput, { name: "name", label: "Name", rules: {
                                required: true,
                            }, control: control, defaultValue: (_d = carrier === null || carrier === void 0 ? void 0 : carrier.name) !== null && _d !== void 0 ? _d : "", error: "name" in errors })),
                    React.createElement(Grid, { xs: 12, container: true, alignItems: "center", justifyContent: "center", spacing: 2 },
                        React.createElement(Grid, { xs: 12, md: 6, lg: 3 },
                            React.createElement(Button, { component: Link, type: "button", variant: "outlined", fullWidth: true, color: "secondary", href: route("broker_carriers", [
                                    (_e = broker === null || broker === void 0 ? void 0 : broker.user) === null || _e === void 0 ? void 0 : _e.id,
                                ]).toString() }, "Cancel")),
                        React.createElement(Grid, { xs: 12, md: 6, lg: 3 },
                            React.createElement(Button, { type: "button", variant: "contained", color: "primary", onClick: handleSubmit(onSubmit), fullWidth: true }, (carrier === null || carrier === void 0 ? void 0 : carrier.id) != null
                                ? "Update Carrier"
                                : "Add Carrier"))))))));
};
export default AbsCarrierForm;
