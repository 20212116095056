import { Box, Grid, Typography } from "@mui/material";
import React from "react";
export var BrokerAddressCard = function (_a) {
    var _b, _c, _d, _e, _f, _g;
    var broker = _a.broker;
    return (React.createElement(Grid, { container: true },
        React.createElement(Grid, { item: true, xs: 12, style: { backgroundColor: "#F1F1F1", marginBottom: "14px" } },
            React.createElement(Box, { mx: 1 },
                React.createElement(Typography, { variant: "h6" }, "Broker Address"))),
        React.createElement(Grid, { container: true, item: true, xs: 12 },
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2" }, "Street 1: "))),
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, (_b = broker.address_1) !== null && _b !== void 0 ? _b : ""))),
        React.createElement(Grid, { container: true, item: true, xs: 12 },
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2" }, "Street 2: "))),
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, (_c = broker.address_2) !== null && _c !== void 0 ? _c : ""))),
        React.createElement(Grid, { container: true, item: true, xs: 12 },
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2" }, "City: "))),
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, (_d = broker.city) !== null && _d !== void 0 ? _d : ""))),
        React.createElement(Grid, { container: true, item: true, xs: 12 },
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2" }, "State: "))),
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, (_f = (_e = broker === null || broker === void 0 ? void 0 : broker.state) === null || _e === void 0 ? void 0 : _e.name) !== null && _f !== void 0 ? _f : ""))),
        React.createElement(Grid, { container: true, item: true, xs: 12 },
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2" }, "Zip: "))),
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, (_g = broker === null || broker === void 0 ? void 0 : broker.zip) !== null && _g !== void 0 ? _g : "")))));
};
