import { Link, usePage } from "@inertiajs/react";
import { Button, Grid } from "@mui/material";
import React, { useState } from "react";
import route from "ziggy-js";
import { hasPermission } from "../../utils/utils";
import Layout from "../Layout";
import BrokerLicenseTable from "./components/BrokerLicenseTable";
import BrokerNav from "./components/BrokerNav";
var AbsLicenses = function (_a) {
    var _b;
    var response_data = _a.response_data;
    var auth = usePage().props.auth;
    var _c = useState(true), menuOpen = _c[0], toggleMenuOpen = _c[1];
    var broker = response_data.broker, carrier_count = response_data.carrier_count, lead_count = response_data.lead_count, document_count = response_data.document_count, abs_licenses = response_data.abs_licenses, license_count = response_data.license_count;
    return (React.createElement(Layout, { title: "Member Info", menuOpen: menuOpen, toggleMenuOpen: toggleMenuOpen },
        React.createElement(Grid, { container: true, spacing: 2 },
            React.createElement(Grid, { container: true, item: true, xs: 12, alignItems: "center", justifyContent: "center" },
                React.createElement(BrokerNav, { broker: broker, lead_count: lead_count, document_count: document_count, carrier_count: carrier_count, license_count: license_count })),
            hasPermission(auth, "add broker-license") && (React.createElement(Grid, { item: true, xs: 12, md: 6, lg: 3 },
                React.createElement(Button, { component: Link, type: "button", variant: "outlined", fullWidth: true, color: "secondary", href: route("abs_add_license", [
                        (_b = broker === null || broker === void 0 ? void 0 : broker.user) === null || _b === void 0 ? void 0 : _b.id,
                    ]).toString() }, "Add License"))),
            React.createElement(Grid, { item: true, xs: 12 },
                React.createElement(BrokerLicenseTable, { data: abs_licenses })))));
};
export default AbsLicenses;
